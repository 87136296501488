import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import SearchIcon from '@mui/icons-material/Search';

const SearchCircleIcon = ({ onClick, sx }) => {
    return (
        <div style={{ position: 'relative', display: 'inline-flex', ...sx, cursor: 'pointer' }} onClick={onClick}>
            <CircleIcon sx={{ color: 'black', fontSize: 40 }} />
            <SearchIcon sx={{
                color: 'white',
                fontSize: 15, // Slightly larger size for a bolder effect
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%) scale(2)' // Scale the icon up a bit
            }} />
        </div>
    );
};

export default SearchCircleIcon;
