import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, List, ListItem, ListItemText, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, getDocs, addDoc, deleteDoc, doc, setDoc, arrayUnion, updateDoc, arrayRemove, getDoc } from 'firebase/firestore';

const TabContentLimitsOnDateRange = ({ userEmail, physician, selectedCalendarToEdit, firestore }) => {
    const [encounterTypes, setEncounterTypes] = useState([]);
    const [encounterTypesToBeShown, setEncounterTypesToBeShown] = useState([]);
    const [linkedGroups, setLinkedGroups] = useState([]);
    const [linkedGroupsToBeShown, setLinkedGroupsToBeShown] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupToBeShown, setOpenPopupToBeShown] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupNameToBeShown, setGroupNameToBeShown] = useState('');
    const [encounterTypesIgnoreCat, setEncounterTypesIgnoreCat] = useState([]);
    const [selectedIgnoreCat, setSelectedIgnoreCat] = useState([]);
    const [openIgnoreCatPopup, setOpenIgnoreCatPopup] = useState(false);
    const [encounterTypesAlwaysOverride, setEncounterTypesAlwaysOverride] = useState([]);
const [selectedAlwaysOverride, setSelectedAlwaysOverride] = useState([]);
const [openAlwaysOverridePopup, setOpenAlwaysOverridePopup] = useState(false);

// Add this new function to fetch the encounter types that always require override
const fetchEncounterTypesAlwaysOverride = async () => {
    const encTypesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
    const encTypesSnapshot = await getDocs(encTypesRef);
    let types = [];
    encTypesSnapshot.forEach((doc) => {
        types.push({ label: doc.id, value: doc.id.toLowerCase(), category: doc.data().category });
    });

    const linksRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
    const linksSnapshot = await getDocs(linksRef);
    let linkedTypes = new Map();
    
    linksSnapshot.forEach((linkDoc) => {
        linkDoc.data().linked.forEach((linkedType) => {
            linkedTypes.set(linkedType, linkDoc.id);
        });
    });
    
    types = types.map((type) => {
        if (linkedTypes.has(type.label)) {
            return { label: linkedTypes.get(type.label), value: linkedTypes.get(type.label).toLowerCase(), category: type.category };
        }
        return type;
    });

    const uniqueTypes = Array.from(new Set(types.map(JSON.stringify))).map(JSON.parse);
    uniqueTypes.sort((a, b) => a.label.localeCompare(b.label));

    setEncounterTypesAlwaysOverride(uniqueTypes);

    // Fetch existing always override encounter types from Firestore
    const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'neverSpotEncTypes', 'neverSpotEncTypes');
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data.neverSpotEncTypes) {
            setSelectedAlwaysOverride(data.neverSpotEncTypes);
        }
    }
};

// Add this to your useEffect
useEffect(() => {
    fetchEncounterTypesAlwaysOverride();
}, []);

// Add these new functions to handle the always override encounter types
const handleAddToAlwaysOverride = async (type) => {
    const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'neverSpotEncTypes', 'neverSpotEncTypes');
    await setDoc(docRef, { neverSpotEncTypes: arrayUnion(type) }, { merge: true });
    setSelectedAlwaysOverride([...selectedAlwaysOverride, type]);
};

const handleOpenAlwaysOverridePopup = () => {
    setOpenAlwaysOverridePopup(true);
};

const handleCloseAlwaysOverridePopup = () => {
    setOpenAlwaysOverridePopup(false);
};

const handleDeleteAlwaysOverride = async (typeValue) => {
    const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'neverSpotEncTypes', 'neverSpotEncTypes');
    
    // Find the exact object to remove
    const typeToRemove = selectedAlwaysOverride.find(type => type.value === typeValue);

    if (typeToRemove) {
        // Remove the selected encounter type object from the neverSpotEncTypes array in Firestore
        await updateDoc(docRef, {
            neverSpotEncTypes: arrayRemove(typeToRemove)
        });

        // Update the local state to reflect the change
        setSelectedAlwaysOverride(selectedAlwaysOverride.filter(type => type.value !== typeValue));
    }
};


    const fetchEncounterTypesIgnoreCat = async () => {
        const encTypesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
        const encTypesSnapshot = await getDocs(encTypesRef);
        let types = [];
        encTypesSnapshot.forEach((doc) => {
            types.push({ label: doc.id, value: doc.id.toLowerCase(), category: doc.data().category });
        });
    
        const linksRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
        const linksSnapshot = await getDocs(linksRef);
        let linkedTypes = new Map();
        
        linksSnapshot.forEach((linkDoc) => {
            linkDoc.data().linked.forEach((linkedType) => {
                linkedTypes.set(linkedType, linkDoc.id);
            });
        });
        
        types = types.map((type) => {
            if (linkedTypes.has(type.label)) {
                return { label: linkedTypes.get(type.label), value: linkedTypes.get(type.label).toLowerCase(), category: type.category };
            }
            return type;
        });
    
        const uniqueTypes = Array.from(new Set(types.map(JSON.stringify))).map(JSON.parse);
        uniqueTypes.sort((a, b) => a.label.localeCompare(b.label));
    
        setEncounterTypesIgnoreCat(uniqueTypes);
    
        // Fetch existing ignored encounter types from Firestore
        const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeIgnoringCat', 'ignoringCat');
        const docSnapshot = await getDoc(docRef);
    
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.iEncTypes) {
                setSelectedIgnoreCat(data.iEncTypes);
            }
        }
    };
    

    useEffect(() => {
        fetchEncounterTypesIgnoreCat();
    }, []);

    const handleAddToIgnoreCat = async (type) => {
        const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeIgnoringCat', 'ignoringCat');
        await setDoc(docRef, { iEncTypes: arrayUnion(type) }, { merge: true });
        setSelectedIgnoreCat([...selectedIgnoreCat, type]);
    };

    const handleOpenIgnoreCatPopup = () => {
        setOpenIgnoreCatPopup(true);
    };

    const handleCloseIgnoreCatPopup = () => {
        setOpenIgnoreCatPopup(false);
    };

    const handleDeleteIgnoreCat = async (typeValue) => {
        const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeIgnoringCat', 'ignoringCat');
        
        // Find the exact object to remove
        const typeToRemove = selectedIgnoreCat.find(type => type.value === typeValue);
    
        if (typeToRemove) {
            // Remove the selected encounter type object from the iEncTypes array in Firestore
            await updateDoc(docRef, {
                iEncTypes: arrayRemove(typeToRemove)
            });
    
            // Update the local state to reflect the change
            setSelectedIgnoreCat(selectedIgnoreCat.filter(type => type.value !== typeValue));
        }
    };

    const fetchData = async () => {
        // Fetch encounter types
        const encTypesCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
        const encSnapshot = await getDocs(encTypesCol);
        const allEncTypes = encSnapshot.docs.map(doc => ({ id: doc.id, name: doc.id, checked: false }));

        // Fetch linked groups and prepare a list of all linked types
        const linksCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
        const linksSnapshot = await getDocs(linksCol);
        const linkedTypes = linksSnapshot.docs.flatMap(doc => doc.data().linked || []);

        // Fetch linked groups for showing to clients and prepare a list of all these linked types
        const toBeShownCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'shownEncType');
        const toBeShownSnapshot = await getDocs(toBeShownCol);
        const toBeShownTypes = toBeShownSnapshot.docs.flatMap(doc => doc.data().linked || []);

        // Filter encounter types to exclude those already linked
        const filteredEncTypes = allEncTypes.filter(et => !linkedTypes.includes(et.name));
        const filteredEncTypesToBeShown = allEncTypes.filter(et => !toBeShownTypes.includes(et.name));
        setEncounterTypes(filteredEncTypes);
        setEncounterTypesToBeShown(filteredEncTypesToBeShown);
        setLinkedGroups(linksSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.id,
            linked: doc.data().linked
        })));
        setLinkedGroupsToBeShown(toBeShownSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.id,
            linked: doc.data().linked
        })));
    };

    useEffect(() => {
        fetchData();
    }, [firestore, userEmail, physician, selectedCalendarToEdit]);

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleOpenPopupToBeShown = () => {
        setOpenPopupToBeShown(true);
    };

    const handleClosePopupToBeShown = () => {
        setOpenPopupToBeShown(false);
    };

    const handleLink = async () => {
        if (!groupName) {
            alert('Please enter a group name.');
            return;
        }
        const linksCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
        const groupDocRef = doc(linksCol, groupName);
        await setDoc(groupDocRef, {
            linked: encounterTypes.filter(et => et.checked).map(et => et.name)
        });
        setGroupName('');
        handleClosePopup();
        fetchData();
    };

    const handleDeleteGroup = async (groupId) => {
        const result = window.confirm("Do you want to delete this group?");
        if (result) {
            const groupDoc = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks', groupId);
            await deleteDoc(groupDoc);
            fetchData();
        }
    };

    const handleLinkToBeShown = async () => {
        if (!groupNameToBeShown) {
            alert('Please enter a group name.');
            return;
        }
        const toBeShownCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'shownEncType');
        const groupDocRef = doc(toBeShownCol, groupNameToBeShown);
        await setDoc(groupDocRef, {
            linked: encounterTypesToBeShown.filter(et => et.checked).map(et => et.name)
        });
        setGroupNameToBeShown('');
        handleClosePopupToBeShown();
        fetchData();
    };

    const handleDeleteGroupToBeShown = async (groupId) => {
        const result = window.confirm("Do you want to delete this group?");
        if (result) {
            const groupDoc = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'shownEncType', groupId);
            await deleteDoc(groupDoc);
            fetchData();
        }
    };

    return (
        <div>
            <h2>Linked Encounter Types:</h2>
            {linkedGroups.map(group => (
                <div key={group.id} style={{ alignItems: 'left', marginBottom: '10px' }}>
                    <Button onClick={() => alert(JSON.stringify(group.linked))} style={{ flexGrow: 1, marginRight: '10px' }}>
                        {group.name}
                    </Button>
                    <IconButton onClick={() => handleDeleteGroup(group.id)} aria-label="delete" style={{ color: 'red' }}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
            <IconButton onClick={handleOpenPopup} color="primary" aria-label="add">
                <AddIcon />
            </IconButton>
            <Dialog open={openPopup} onClose={handleClosePopup}>
                <DialogTitle>Add Linked Encounter Types Group</DialogTitle>
                <DialogContent>
                    <List>
                        {encounterTypes.map((type, index) => (
                            <ListItem key={type.id}>
                                <Checkbox
                                    checked={type.checked}
                                    onChange={e => {
                                        let newEncounterTypes = [...encounterTypes];
                                        newEncounterTypes[index].checked = e.target.checked;
                                        setEncounterTypes(newEncounterTypes);
                                    }}
                                />
                                <ListItemText primary={type.name} />
                            </ListItem>
                        ))}
                    </List>
                    <TextField
                        label="Group Name"
                        value={groupName}
                        onChange={e => setGroupName(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLink}>LINK</Button>
                </DialogActions>
            </Dialog>

            <h2>Linked For The Way Encounter Types Will Be Visible To Clients:</h2>
            {linkedGroupsToBeShown.map(group => (
                <div key={group.id} style={{ alignItems: 'left', marginBottom: '10px' }}>
                    <Button onClick={() => alert(JSON.stringify(group.linked))} style={{ flexGrow: 1, marginRight: '10px' }}>
                        {group.name}
                    </Button>
                    <IconButton onClick={() => handleDeleteGroupToBeShown(group.id)} aria-label="delete" style={{ color: 'red' }}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
            <IconButton onClick={handleOpenPopupToBeShown} color="primary" aria-label="add">
                <AddIcon />
            </IconButton>
            <Dialog open={openPopupToBeShown} onClose={handleClosePopupToBeShown}>
                <DialogTitle>Add Linked To Be Shown Encounter Types Group</DialogTitle>
                <DialogContent>
                    <List>
                        {encounterTypesToBeShown.map((type, index) => (
                            <ListItem key={type.id}>
                                <Checkbox
                                    checked={type.checked}
                                    onChange={e => {
                                        let newEncounterTypes = [...encounterTypesToBeShown];
                                        newEncounterTypes[index].checked = e.target.checked;
                                        setEncounterTypesToBeShown(newEncounterTypes);
                                    }}
                                />
                                <ListItemText primary={type.name} />
                            </ListItem>
                        ))}
                    </List>
                    <TextField
                        label="Group Name"
                        value={groupNameToBeShown}
                        onChange={e => setGroupNameToBeShown(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLinkToBeShown}>LINK</Button>
                </DialogActions>
            </Dialog>

            <h2>Encounter Types That Ignore Category Count:</h2>
            {selectedIgnoreCat.map((group, index) => (
                <div key={index} style={{ alignItems: 'left', marginBottom: '10px' }}>
                    <Button onClick={() => alert(JSON.stringify(group))} style={{ flexGrow: 1, marginRight: '10px' }}>
                        {group.label}
                    </Button>
                    <IconButton onClick={() => handleDeleteIgnoreCat(group.value)} aria-label="delete" style={{ color: 'red' }}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
            <IconButton onClick={handleOpenIgnoreCatPopup} color="primary" aria-label="add">
                <AddIcon />
            </IconButton>
            <Dialog open={openIgnoreCatPopup} onClose={handleCloseIgnoreCatPopup}>
                <DialogTitle>Add Encounter Types That Ignore Category Count</DialogTitle>
                <DialogContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <List>
                        {encounterTypesIgnoreCat.map((type, index) => (
                            <ListItem key={type.value}>
                                <Checkbox
                                    checked={selectedIgnoreCat.some(selected => selected.value === type.value)}
                                    onChange={() => handleAddToIgnoreCat(type)}
                                />
                                <ListItemText primary={type.label} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseIgnoreCatPopup}>Close</Button>
                </DialogActions>
            </Dialog>

            <h2>Encounter Types To Always Require Override:</h2>
{selectedAlwaysOverride.map((group, index) => (
    <div key={index} style={{ alignItems: 'left', marginBottom: '10px' }}>
        <Button onClick={() => alert(JSON.stringify(group))} style={{ flexGrow: 1, marginRight: '10px' }}>
            {group.label}
        </Button>
        <IconButton onClick={() => handleDeleteAlwaysOverride(group.value)} aria-label="delete" style={{ color: 'red' }}>
            <DeleteIcon />
        </IconButton>
    </div>
))}
<IconButton onClick={handleOpenAlwaysOverridePopup} color="primary" aria-label="add">
    <AddIcon />
</IconButton>
<Dialog open={openAlwaysOverridePopup} onClose={handleCloseAlwaysOverridePopup}>
    <DialogTitle>Add Encounter Types To Always Require Override</DialogTitle>
    <DialogContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <List>
            {encounterTypesAlwaysOverride.map((type, index) => (
                <ListItem key={type.value}>
                    <Checkbox
                        checked={selectedAlwaysOverride.some(selected => selected.value === type.value)}
                        onChange={() => handleAddToAlwaysOverride(type)}
                    />
                    <ListItemText primary={type.label} />
                </ListItem>
            ))}
        </List>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseAlwaysOverridePopup}>Close</Button>
    </DialogActions>
</Dialog>

        </div>
    );
};

export default TabContentLimitsOnDateRange;

// import React, { useState, useEffect } from 'react';
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, List, ListItem, ListItemText, TextField, IconButton } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { collection, getDocs, addDoc, deleteDoc, doc, setDoc } from 'firebase/firestore';

// const TabContentLimitsOnDateRange = ({ userEmail, physician, selectedCalendarToEdit, firestore }) => {
//     const [encounterTypes, setEncounterTypes] = useState([]);
//     const [encounterTypesToBeShown, setEncounterTypesToBeShown] = useState([]);
//     const [linkedGroups, setLinkedGroups] = useState([]);
//     const [linkedGroupsToBeShown, setLinkedGroupsToBeShown] = useState([]);
//     const [openPopup, setOpenPopup] = useState(false);
//     const [groupName, setGroupName] = useState('');
//     const [groupNameToBeShown, setGroupNameToBeShown] = useState('');

//     // Unified function to fetch encounter types and linked groups
//     const fetchData = async () => {
//         // Fetch encounter types
//         const encTypesCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
//         const encSnapshot = await getDocs(encTypesCol);
//         const allEncTypes = encSnapshot.docs.map(doc => ({ id: doc.id, name: doc.id, checked: false }));  // Assume doc.id as name

//         // Fetch linked groups and prepare a list of all linked types
//         const linksCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
//         const linksSnapshot = await getDocs(linksCol);
//         const linkedTypes = linksSnapshot.docs.flatMap(doc => doc.data().linked || []);

//         // Fetch linked groups for showing to clients and prepare a list of all these linked types
//         const toBeShownCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'shownEncType');
//         const toBeShownSnapshot = await getDocs(toBeShownCol);
//         const toBeShownTypes = toBeShownSnapshot.docs.flatMap(doc => doc.data().linked || []);

//         // Filter encounter types to exclude those already linked
//         const filteredEncTypes = allEncTypes.filter(et => !linkedTypes.includes(et.name));
//         const filteredEncTypesToBeShown = allEncTypes.filter(et => !toBeShownTypes.includes(et.name));
//         setEncounterTypes(filteredEncTypes);
//         setEncounterTypesToBeShown(filteredEncTypesToBeShown);
//         setLinkedGroups(linksSnapshot.docs.map(doc => ({
//             id: doc.id,
//             name: doc.id,
//             linked: doc.data().linked
//         })));
//         setLinkedGroupsToBeShown(toBeShownSnapshot.docs.map(doc => ({
//             id: doc.id,
//             name: doc.id,
//             linked: doc.data().linked
//         })));
//     };

//     useEffect(() => {
//         fetchData();
//     }, [firestore, userEmail, physician, selectedCalendarToEdit]);

//     const handleOpenPopup = () => {
//         setOpenPopup(true);
//     };

//     const handleClosePopup = () => {
//         setOpenPopup(false);
//     };

//     const handleLink = async () => {
//         if (!groupName) {
//             alert('Please enter a group name.');
//             return;
//         }
//         const linksCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
//         const groupDocRef = doc(linksCol, groupName); // Create a doc reference with groupName as the ID
//         await setDoc(groupDocRef, {
//             linked: encounterTypes.filter(et => et.checked).map(et => et.name)
//         });
//         setGroupName('');
//         handleClosePopup();
//         fetchData();  // Refetch the data to update the state after linking
//     };

//     const handleDeleteGroup = async (groupId) => {
//         const result = window.confirm("Do you want to delete this group?");
//         if (result) {
//             const groupDoc = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks', groupId);
//             await deleteDoc(groupDoc);
//             fetchData();  // Refetch the data to update the state after deleting
//         }
//     };



//     const handleLinkToBeShown = async () => {
//         if (!groupNameToBeShown) {
//             alert('Please enter a group name.');
//             return;
//         }
//         const toBeShownCol = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'shownEncType');
//         const groupDocRef = doc(toBeShownCol, groupNameToBeShown); // Create a doc reference with groupName as the ID
//         await setDoc(groupDocRef, {
//             linked: encounterTypesToBeShown.filter(et => et.checked).map(et => et.name)
//         });
//         setGroupNameToBeShown('');
//         handleClosePopup();
//         fetchData();  // Refetch the data to update the state after linking
//     };

//     const handleDeleteGroupToBeShown = async (groupId) => {
//         const result = window.confirm("Do you want to delete this group?");
//         if (result) {
//             const groupDoc = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'shownEncType', groupId);
//             await deleteDoc(groupDoc);
//             fetchData();  // Refetch the data to update the state after deleting
//         }
//     };

//     return (
//         <div>
//             <h2>Linked Encounter Types:</h2>
//             {linkedGroups.map(group => (
//                 <div key={group.id} style={{ alignItems: 'left', marginBottom: '10px' }}>
//                     <Button onClick={() => alert(JSON.stringify(group.linked))} style={{ flexGrow: 1, marginRight: '10px' }}>
//                         {group.name}
//                     </Button>
//                     <IconButton onClick={() => handleDeleteGroup(group.id)} aria-label="delete" style={{ color: 'red' }}>
//                         <DeleteIcon />
//                     </IconButton>
//                 </div>
//             ))}
//             <IconButton onClick={handleOpenPopup} color="primary" aria-label="add">
//                 <AddIcon />
//             </IconButton>
//             <Dialog open={openPopup} onClose={handleClosePopup}>
//                 <DialogTitle>Add Linked Encounter Types Group</DialogTitle>
//                 <DialogContent>
//                     <List>
//                         {encounterTypes.map((type, index) => (
//                             <ListItem key={type.id}>
//                                 <Checkbox
//                                     checked={type.checked}
//                                     onChange={e => {
//                                         let newEncounterTypes = [...encounterTypes];
//                                         newEncounterTypes[index].checked = e.target.checked;
//                                         setEncounterTypes(newEncounterTypes);
//                                     }}
//                                 />
//                                 <ListItemText primary={type.name} />
//                             </ListItem>
//                         ))}
//                     </List>
//                     <TextField
//                         label="Group Name"
//                         value={groupName}
//                         onChange={e => setGroupName(e.target.value)}
//                         fullWidth
//                     />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleLink}>LINK</Button>
//                 </DialogActions>
//             </Dialog>



// <h2>Linked To Be Shown Encounter Types For Clients:</h2>
// {linkedGroupsToBeShown.map(group => (
//     <div key={group.id} style={{ alignItems: 'left', marginBottom: '10px' }}>
//         <Button onClick={() => alert(JSON.stringify(group.linked))} style={{ flexGrow: 1, marginRight: '10px' }}>
//             {group.name}
//         </Button>
//         <IconButton onClick={() => handleDeleteGroupToBeShown(group.id)} aria-label="delete" style={{ color: 'red' }}>
//             <DeleteIcon />
//         </IconButton>
//     </div>
// ))}
// <IconButton onClick={handleOpenPopup} color="primary" aria-label="add">
//     <AddIcon />
// </IconButton>
// <Dialog open={openPopup} onClose={handleClosePopup}>
//     <DialogTitle>Add Linked To Be Shown Encounter Types Group</DialogTitle>
//     <DialogContent>
//         <List>
//             {encounterTypesToBeShown.map((type, index) => (
//                 <ListItem key={type.id}>
//                     <Checkbox
//                         checked={type.checked}
//                         onChange={e => {
//                             let newEncounterTypes = [...encounterTypesToBeShown];
//                             newEncounterTypes[index].checked = e.target.checked;
//                             setEncounterTypesToBeShown(newEncounterTypes);
//                         }}
//                     />
//                     <ListItemText primary={type.name} />
//                 </ListItem>
//             ))}
//         </List>
//         <TextField
//             label="Group Name"
//             value={groupNameToBeShown}
//             onChange={e => setGroupNameToBeShown(e.target.value)}
//             fullWidth
//         />
//     </DialogContent>
//     <DialogActions>
//         <Button onClick={handleLinkToBeShown}>LINK</Button>
//     </DialogActions>
// </Dialog>
// </div>
//     );
// };

// export default TabContentLimitsOnDateRange;
