import React, { useState } from 'react';
import './QuickAdd.css';
import { parse } from 'date-fns';
import isValid from 'date-fns/isValid';

const QuickAddWindow = ({ visible, onCancel, onAdd, data, setData }) => {
  const handleChange = (e, rowIndex, colIndex) => {
    const newData = [...data];
    newData[rowIndex][colIndex] = e.target.value;
    setData(newData);
  };

  // This array contains the widths for each column. Adjust these as necessary.
  const columnWidths = ['10%', '20%', '15%', '10%', '10%', '10%', '10%', '15%'];

  return visible ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1000 }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', maxWidth: '1200px', width: '90%' }}>
        <h2 style={{ marginBottom: '20px' }}>Quickly add new or additional recipients here!</h2>
        <table style={{ alignItems: 'center', width: '100%', marginBottom: '20px', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>Cell Phone</th>
              <th>Email</th>
              <th>Name</th>
              <th>Language</th>
              <th>Old Date</th>
              <th>New Date</th>
              <th>Time/Group</th>
              <th>Encounter Type</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, colIndex) => (
                  <td key={colIndex} style={{ alignItems: 'center', padding: '5px', border: '1px solid #ccc', width: columnWidths[colIndex] }}>
                    <input type="text" value={cell || ''} onChange={(e) => handleChange(e, rowIndex, colIndex)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '96%' }} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button onClick={onCancel} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#ccc', border: 'none', color: '#333', textTransform: 'uppercase', fontWeight: 'bold', cursor: 'pointer' }}>
            Cancel
          </button>
          <button onClick={onAdd} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', border: 'none', color: '#fff', textTransform: 'uppercase', fontWeight: 'bold', cursor: 'pointer' }}>
            Add
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const QuickAdd = ({
  open,
  handleClose,
  setNewSchedule,
  newSchedule,
  setEndName,
  setCurrentLoadedClients,
  setSendMsgsButtonDisabled,
  setSendMsgsButtonColor,
  userEmail,
  currentLoadedClients
}) => {
  const [modalData, setModalData] = useState(Array.from({ length: 5 }, () => Array(8).fill(null)));
  const [compatibilityMessageAcknowledged, setCompatibilityMessageAcknowledged] = useState(false);
  const [proceedWithoutDatesDecisionMade, setProceedWithoutDatesDecisionMade] = useState(false);
  const [proceedWithoutDates, setProceedWithoutDates] = useState(false);

  const handleAdd = () => {
    
    const newRows = modalData
      .filter(data => data.some(field => field)) // Filter out rows that don't contain any data
      .map((data, index) => {
        // Create row object with unique IDs
        const row = {
          id: Date.now() + index,
          phone: data[0],
          email: data[1],
          name: data[2],
          xLanguage: data[3],
          oldDate: data[4],
          newDate: data[5],
          newGroup: data[6],
          encType: data[7]
        };
        return row;
      });

      // let missingNameMessageAcknowledged = false;
      // let missingEncTypeMessageAcknowledged = false;

    // Check for empty names
    const missingNames = newRows.some(row => row.name === null);
    if (missingNames) {
      // missingNameMessageAcknowledged = true
      alert("Missing name detected! The application cannot add clients with missing names. Please add the missing names accordingly and then click ADD again.");
      return;  // Return early if names are missing
    }
    

        // Check for empty encType
        const missingEncType = newRows.some(row => row.encType === null);
        // console.log(userEmail)
        if (missingEncType && userEmail === 'clinica@uro-med.net') {
          // missingEncTypeMessageAcknowledged = true
          alert("Missing Encounter Type detected! Encounter types are required for all entries. Please add the missing Encounter Types(s) accordingly and then click ADD again.");
          return;  // Return early if encTypes are missing
        }

    // Normalize the xLanguage property
    newRows.forEach(row => {
      if (row.xLanguage) {
        const normalizedLanguage = row.xLanguage.toLowerCase();
        const englishVariations = ["english", "eng", "e", "ingles", "ing", "i"];
        const spanishVariations = ["esp", "espanol", "español", "spa", "spanish", "s"];
        if (englishVariations.includes(normalizedLanguage)) {
          row.xLanguage = "English";
        } else if (spanishVariations.includes(normalizedLanguage)) {
          row.xLanguage = "Spanish";
        }
      } else {
        row.xLanguage = "Spanish"; // Set to Spanish if null or empty
      }

    //   if (typeof row.oldDate === 'string') {
    //     row.oldDate = new Date(row.oldDate);
    // }
    // if (typeof row.newDate === 'string') {
    //     row.newDate = new Date(row.newDate);
    // }

    function removeLeadingZeros(dateStr) {
      // This function targets the MM/DD/YYYY and DD/MM/YYYY formats specifically.
      // It splits the date string by '/' or '-', removes leading zeros from month and day,
      // and then joins the parts back together.
      return dateStr
        .split(/\/|-/) // Split by '/' or '-'
        .map(part => part.replace(/^0+/, '')) // Remove leading zeros in each part
        .join('/'); // Join back with '/'
    }
    
    if (typeof row.oldDate === 'string') {
      const cleanedOldDate = removeLeadingZeros(row.oldDate);
      row.oldDate = new Date(cleanedOldDate);
    }
    if (typeof row.newDate === 'string') {
      const cleanedNewDate = removeLeadingZeros(row.newDate);
      row.newDate = new Date(cleanedNewDate);
    }
    
    
    
    // Use state setters to set the state
    if (row.oldDate && row.newDate) {
        // No changes needed if both dates are valid.
    } else if (row.oldDate || row.newDate) {
        if (!row.newDate && row.oldDate) {
            row.newDate = row.oldDate
        }
        if (!row.oldDate && row.newDate) {
            row.oldDate = row.newDate
        }
    
        if (!compatibilityMessageAcknowledged) {
            alert("For compatibility reasons both date columns were filled with the same date that was entered in the excel file. This will not affect the message if your intention was to use just 1 date on the template. Otherwise, check your excel file for errors in the dates and reload the corrected file.");
            setCompatibilityMessageAcknowledged(true); // Use state setter
        }
    } else {
        if (!proceedWithoutDatesDecisionMade) {
            const proceed = window.confirm("There are no dates entered in your excel file. If you are not going to use a template that uses dates for your message, you may proceed. However, if this is a mistake, cancel, fix, and reload the corrected file. Do you wish to proceed?");
            setProceedWithoutDatesDecisionMade(true); // Use state setter
            setProceedWithoutDates(proceed); // Use state setter
    
            if (!proceed) {
                throw new Error("User cancelled the file loading");
            }
        }
    }
    

      //old code partially working
      // Convert oldDate and newDate to Date objects if they are strings
      // if (typeof row.oldDate === 'string') {
      //   row.oldDate = new Date(row.oldDate);
      // }
      // if (typeof row.newDate === 'string') {
      //   row.newDate = new Date(row.newDate);
      // }

      // // Use state setters to set the state
      // if (row.oldDate && row.newDate) {
      //   row.newDate = new Date(row.newDate.setDate(row.newDate.getDate()));
      //   row.oldDate = new Date(row.oldDate.setDate(row.oldDate.getDate()));
      // } else if (row.oldDate || row.newDate) {
      //   if (!row.newDate) {
      //     row.oldDate = new Date(row.oldDate.setDate(row.oldDate.getDate()));
      //     row.newDate = row.oldDate;
      //   }
      //   if (!row.oldDate) {
      //     row.newDate = new Date(row.newDate.setDate(row.newDate.getDate()));
      //     row.oldDate = row.newDate;
      //   }

      //   if (!compatibilityMessageAcknowledged) {
      //     alert("For compatibility reasons both date columns were filled with the same date that was entered in the excel file. This will not affect the message if your intention was to use just 1 date on the template. Otherwise, check your excel file for errors in the dates and reload the corrected file.");
      //     setCompatibilityMessageAcknowledged(true); // Use state setter
      //   }
      // } else {
      //   if (!proceedWithoutDatesDecisionMade) {
      //     const proceed = window.confirm("There are no dates entered in your excel file. If you are not going to use a template that uses dates for your message, you may proceed. However, if this is a mistake, cancel, fix, and reload the corrected file. Do you wish to proceed?");
      //     setProceedWithoutDatesDecisionMade(true); // Use state setter
      //     setProceedWithoutDates(proceed); // Use state setter

      //     if (!proceed) {
      //       throw new Error("User cancelled the file loading");
      //     }
      //   }
      // }

      // Reformat the newGroup property to desired format
      if (row.newGroup) {
        const shorthandPattern = /(\d{1,2})(a|p)\b/i;
        const spanishFormatPattern = /(\d{1,2}) y (\d{2})\s*(am|pm|a|p|AM|PM)?\b/i;
        const incompleteTimePattern = /(\d{1,2}):?\s*(am|pm|a|p|AM|PM)\b/i;
        const timePattern = /(\d{1,2}):(\d{2})\s*(am|pm|a|p|AM|PM)?\b/i;

        row.newGroup = row.newGroup.toLowerCase();

        if (shorthandPattern.test(row.newGroup)) {
          row.newGroup = row.newGroup.replace(shorthandPattern, (match, p1, p2) => `${p1}:00 ${p2 === 'a' ? 'A.M.' : 'P.M.'}`);
        } else if (spanishFormatPattern.test(row.newGroup)) {
          row.newGroup = row.newGroup.replace(spanishFormatPattern, (match, p1, p2, p3) => `${p1}:${p2} ${p3 ? (p3.startsWith('a') ? 'A.M.' : 'P.M.') : 'A.M.'}`);
        } else if (incompleteTimePattern.test(row.newGroup)) {
          row.newGroup = row.newGroup.replace(incompleteTimePattern, (match, p1, p2) => `${p1}:00 ${p2.startsWith('a') ? 'A.M.' : 'P.M.'}`);
        } else if (timePattern.test(row.newGroup)) {
          row.newGroup = row.newGroup.replace(timePattern, (match, p1, p2, p3) => `${p1}:${p2} ${p3 ? (p3.startsWith('a') ? 'A.M.' : 'P.M.') : 'A.M.'}`);
        }
      }

      return row;
    });

    // Make sure newSchedule is initialized as an array if it's not already
    if (!newSchedule) {
      newSchedule = [];
    }

    setNewSchedule(prevNewSchedule => [...newRows, ...prevNewSchedule]);

    setModalData(Array.from({ length: 5 }, () => Array(8).fill(null)));

    let lastName;

    if (newSchedule && newSchedule.length > 0) {
      lastName = newSchedule.slice(-1);
    } else {
      lastName = newRows.slice(-1);
    }


    if (lastName[0]) {
      setEndName(lastName[0].name);
    }
    setCurrentLoadedClients(currentLoadedClients + newRows.length);
    setSendMsgsButtonDisabled(false);
    setSendMsgsButtonColor("#ADB5BD");

    // Close the modal
    handleClose();
  };

  return (
    <div>
      <QuickAddWindow
        visible={open}
        onCancel={handleClose}
        onAdd={handleAdd}
        data={modalData}
        setData={setModalData}
      />
    </div>
  );
};

export default QuickAdd;
