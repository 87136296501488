import React from 'react';
import { CirclePicker } from 'react-color';

const CustomCirclePicker = (props) => {
  // Add black to the default color array
  const customColors = [
    "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5",
    "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", 
    "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", 
    "#9e9e9e", "#607d8b", "#000000" // Include black as the last color
  ];

  return (
    <CirclePicker
      colors={customColors}
      onChangeComplete={props.handleColorChange}
      {...props}
    />
  );
};

export default CustomCirclePicker;
