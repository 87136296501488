import React from 'react';
import { CircularProgress, Button } from '@mui/material';

const ProgressOverlaySpotSearch = ({ isLoadingSpotSearch, onStopSearch }) => {
  if (!isLoadingSpotSearch) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1500,
    }}>
      <div style={{
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CircularProgress variant="indeterminate" style={{ marginTop: '13px' }} />
        <Button onClick={onStopSearch} style={{ marginTop: '20px' }}>Stop Search</Button>
      </div>
    </div>
  );
};

export default ProgressOverlaySpotSearch;



// import React, { useState, useEffect } from 'react';
// import { CircularProgress, Button } from '@mui/material';

// const ProgressOverlaySpotSearch = ({ isLoadingSpotSearch, onStopSearch }) => {
//   if (!isLoadingSpotSearch) return null;

//   return (
//     <div style={{
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//       alignItems: 'center',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       zIndex: 1500,
//     }}>
//       <div style={{
//         padding: '20px',
//         borderRadius: '10px',
//         backgroundColor: '#fff',
//         display: 'flex',
//         flexDirection: 'column', // Changed to column to stack elements vertically
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}>
//         <CircularProgress variant="indeterminate" />
//         <Button onClick={onStopSearch} style={{ marginTop: '20px' }}>Stop Search</Button> {/* Added Stop Search button */}
//       </div>
//     </div>
//   );
// };

// export default ProgressOverlaySpotSearch;

