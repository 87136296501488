import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress, Modal } from '@mui/material';

const BasicProgressOverlay = ({ isInProcess }) => {
  if (!isInProcess) return null;

  return ReactDOM.createPortal(
    <Modal
      open={isInProcess}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9997, // Ensure this is lower than NoSpotModal
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }}
    >
      <div
        style={{
          padding: '20px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress variant="indeterminate" />
      </div>
    </Modal>,
    document.body
  );
};

export default BasicProgressOverlay;


// import React from 'react';
// import { CircularProgress, Modal } from '@mui/material';

// const BasicProgressOverlay = ({ isInProcess }) => {
//   return (
//     <Modal
//       open={isInProcess}
//       disableAutoFocus
//       disableEnforceFocus
//       disableRestoreFocus
//       style={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         zIndex: 1400,
//         // zIndex: (theme) => theme.zIndex.modal + 1, // Make sure the modal is above the overlay    
//       }}
//       slotProps={{
//         backdrop: {
//           sx: {
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           },
//         },
//       }}
//     >
//       <div
//         style={{
//           padding: '20px',
//           borderRadius: '10px',
//           backgroundColor: '#fff',
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <CircularProgress variant="indeterminate" />
//       </div>
//     </Modal>
//   );
// };

// export default BasicProgressOverlay;










// import React from 'react';
// import { CircularProgress } from '@mui/material';

// const BasicProgressOverlay = ({ isInProcess }) => {
//   if (!isInProcess) return null;

//   return (
//     <div style={{
//       position: 'fixed', // Use 'fixed' to keep the overlay centered and covering the entire screen
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100vh', // Full viewport height
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center', // Vertically center the content
//       alignItems: 'center', // Horizontally center the content
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       zIndex: 1400,
//     }}>
//       <div style={{
//         padding: '20px',
//         borderRadius: '10px',
//         backgroundColor: '#fff',
//         display: 'flex',
//         flexDirection: 'column', 
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}>
//         <CircularProgress variant="indeterminate" />
//       </div>
//     </div>
//   );
// };

// export default BasicProgressOverlay;


// // import React, { useState, useEffect } from 'react';
// // import { CircularProgress, Button } from '@mui/material';

// // const BasicProgressOverlay = ({ isInProcess }) => {
// //   if (!isInProcess) return null;

// //   return (
// //     <div style={{
// //       position: 'relative',
// //       top: 0,
// //       left: 0,
// //       width: '100%',
// //       height: '100%',
// //       display: 'flex',
// //       flexDirection: 'column',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       backgroundColor: 'rgba(0, 0, 0, 0.5)',
// //       zIndex: 1400,
// //     }}>
// //       <div style={{
// //         padding: '20px',
// //         borderRadius: '10px',
// //         backgroundColor: '#fff',
// //         display: 'flex',
// //         flexDirection: 'column', // Changed to column to stack elements vertically
// //         justifyContent: 'center',
// //         alignItems: 'center',
// //       }}>
// //         <CircularProgress variant="indeterminate" />
// //       </div>
// //     </div>
// //   );
// // };

// // export default BasicProgressOverlay;

