import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Checkbox, FormControlLabel, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import WeekDayLimit from './WeekDayLimit';
import { collection, getDocs, doc, deleteDoc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import BasicProgressOverlay from './components/BasicProgressOverlay'; 
import { set } from 'date-fns';

const subcollections = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const TabContentLimitsByEncounterType = ({ userEmail, physician, selectedCalendarToEdit, firestore }) => {
    const weeksOfMonth = ["first", "second", "third", "fourth", "fifth"];
    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const [selectedWeekDay, setSelectedWeekDay] = useState({ week: null, day: null });
    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const [copySelections, setCopySelections] = useState({first: false, second: false, third: false, fourth: false, fifth: false});
    const [weekDayLimits, setWeekDayLimits] = useState([]);
    const [dialogContent, setDialogContent] = useState([]);
    const [encounterTypes, setEncounterTypes] = useState ([]);
    const [targetWeeks, setTargetWeeks] = useState([]);
    const [fromWeek, setFromWeek] = useState("")
    const [copying, setCopying] = useState(false);
    const [isInProcess, setIsInProcess] = useState(false);

// Later in your code, ensure this state is only modified to store arrays

// useEffect(() => {
// console.log(copySelections)
// console.log(targetWeeks)
// }, [copySelections]);

useEffect(() => {
    console.log("Copy Selections updated:", copySelections);
    // Derive targetWeeks based on copySelections
    const newTargetWeeks = Object.entries(copySelections)
        .filter(([key, value]) => value)
        .map(([key]) => key);

    setTargetWeeks(newTargetWeeks);  // Update targetWeeks state

}, [copySelections]); // Re-run the effect when copySelections changes

useEffect(() => {
    // This will log after targetWeeks is updated
    console.log("Target Weeks updated:", targetWeeks);
}, [targetWeeks]); // This effect runs when targetWeeks changes


    useEffect(() => {

        const fetchEncounterTypes = async () => {
            // Fetch encounter types from 'encTypes' collection
            const encTypesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
            const encTypesSnapshot = await getDocs(encTypesRef);
            let types = [];
            encTypesSnapshot.forEach((doc) => {
                // Change this line to correctly access the category data
                types.push({ label: doc.id, value: doc.id.toLowerCase(), category: doc.data().category });
            });
        
            // Fetch linked groups from 'encTypeLinks' subcollection
            const linksRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
            const linksSnapshot = await getDocs(linksRef);
            let linkedTypes = new Map();
        
            // Map each type to its group name (if linked)
            linksSnapshot.forEach((linkDoc) => {
                linkDoc.data().linked.forEach((linkedType) => {
                    linkedTypes.set(linkedType, linkDoc.id);
                });
            });
        
            // Update the types array to replace types with their group names where applicable
            types = types.map((type) => {
                if (linkedTypes.has(type.label)) {
                    return { label: linkedTypes.get(type.label), value: linkedTypes.get(type.label).toLowerCase(), category: type.category };
                }
                return type;
            });
        
            // To ensure uniqueness (as multiple types could be linked under one group name), use a Set
            const uniqueTypes = Array.from(new Set(types.map(JSON.stringify))).map(JSON.parse);
        
            // Sort types by label
            uniqueTypes.sort((a, b) => a.label.localeCompare(b.label));
        
            setEncounterTypes(uniqueTypes);
        };

        // const fetchEncounterTypes = async () => {
        //     // Fetch encounter types from 'encTypes' collection
        //     const encTypesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
        //     const encTypesSnapshot = await getDocs(encTypesRef);
        //     let types = [];
        //     encTypesSnapshot.forEach((doc) => {
        //         types.push({ label: doc.id, value: doc.id.toLowerCase(), category: doc.data.category });
        //     });
        
        //     // Fetch linked groups from 'encTypeLinks' subcollection
        //     const linksRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
        //     const linksSnapshot = await getDocs(linksRef);
        //     let linkedTypes = new Map();
        
        //     // Map each type to its group name (if linked)
        //     linksSnapshot.forEach((linkDoc) => {
        //         linkDoc.data().linked.forEach((linkedType) => {
        //             linkedTypes.set(linkedType, linkDoc.id);
        //         });
        //     });
        
        //     // Update the types array to replace types with their group names where applicable
        //     types = types.map((type) => {
        //         if (linkedTypes.has(type.label)) {
        //             return { label: linkedTypes.get(type.label), value: linkedTypes.get(type.label).toLowerCase(), category: type.category };
        //         }
        //         return type;
        //     });
        
        //     // To ensure uniqueness (as multiple types could be linked under one group name), use a Set
        //     const uniqueTypes = Array.from(new Set(types.map(JSON.stringify))).map(JSON.parse);
        
        //     // Sort types by label
        //     uniqueTypes.sort((a, b) => a.label.localeCompare(b.label));
        
        //     setEncounterTypes(uniqueTypes);
        // };
        

        fetchEncounterTypes();
    }, [firestore, userEmail, physician, selectedCalendarToEdit]);

    useEffect(() => {
        if (!selectedWeekDay.week || !selectedWeekDay.day) return;

        const dayCollectionRef = collection(firestore, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${selectedWeekDay.week}/${selectedWeekDay.day}`);
        getDocs(dayCollectionRef).then(querySnapshot => {
            const fetchedLimits = [];
            querySnapshot.forEach(doc => {
                fetchedLimits.push(doc.data());
            });
            setWeekDayLimits(fetchedLimits);
        }).catch(error => {
            console.error("Error fetching week day limits:", error);
        });
    }, [selectedWeekDay, userEmail, physician, selectedCalendarToEdit, firestore]);

    const handleWeekDaySelection = (week, day) => {
        setSelectedWeekDay({ week, day });
    };

    const handleCopyDialogOpen = (excludedWeek) => {
        const weekNames = ["first", "second", "third", "fourth", "fifth"];
        let weekLabel = weekNames[excludedWeek]; 
        setFromWeek(weekLabel)
        const filteredWeeks = weeksOfMonth.filter((_, index) => index !== excludedWeek);
        setDialogContent(filteredWeeks);
        setShowCopyDialog(true);
    };

    const handleCopySelectionChange = (weekLabel, idx) => {       
        setCopySelections(prev => ({ ...prev, [weekLabel]: !prev[weekLabel] }));
    };

    // const copyWeekData = () => {
    //     console.log('Copying data to:', Object.keys(copySelections).filter(key => copySelections[key]));
    //     setCopySelections({});
    //     setShowCopyDialog(false);
    // };

    // Helper function to fetch all documents from a subcollection
async function fetchAllDocumentsFromSubcollection(firestore, path) {
    const ref = collection(firestore, path);
    const snapshot = await getDocs(ref);
    const documents = [];
    snapshot.forEach(doc => {
        documents.push({ id: doc.id, data: doc.data() });
    });
    return documents;
}

// Function to fetch all subcollections and documents for a given week
async function fetchWeekData(firestore, path) {
    const weekData = {};
    // Loop through known subcollections
    for (const subcollection of subcollections) {
        const subcollectionPath = `${path}/${subcollection}`;
        const docs = await fetchAllDocumentsFromSubcollection(firestore, subcollectionPath);
        weekData[subcollection] = docs;
    }
    return weekData;
}

// Deletes all subcollections under a week
async function deleteWeek(firestore, path) {
    // Loop through known subcollections
    for (const subcollection of subcollections) {
        const subcollectionPath = `${path}/${subcollection}`;
        const docsSnapshot = await getDocs(collection(firestore, subcollectionPath));
        for (const doc of docsSnapshot.docs) {
            await deleteDoc(doc.ref); // Delete each document
        }
    }
}


// --------------------------------------------------------------

// Function to copy a single subcollection
async function copySubcollection(sourceDocRef, targetDocRef, subcollectionName) {
    const sourceSubcollectionRef = collection(sourceDocRef, subcollectionName);
    const snapshot = await getDocs(sourceSubcollectionRef);
    for (const doc of snapshot.docs) {  // Changed to for...of for proper async handling
        const targetSubDocRef = doc(targetDocRef, subcollectionName, doc.id);
        await setDoc(targetSubDocRef, doc.data());
    }
}

async function copyWeekData(firestore, sourceWeek, userEmail, physician, selectedCalendarToEdit) {
    console.log("Starting copyWeekData function");

    const baseWeeksPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/`;
    console.log("Base path for weeks of month set:", baseWeeksPath);

    const weeksRef = collection(firestore, baseWeeksPath);
    console.log("Fetching all documents from weeks of month to check against target weeks");
    const allWeeksDocs = await getDocs(weeksRef);
    console.log("Documents fetched:", allWeeksDocs.docs.map(doc => doc.id));

    console.log(allWeeksDocs.docs)
    // console.log(targetWeeks)

    const weeksToDelete = allWeeksDocs.docs.filter(doc => targetWeeks.includes(doc.id));
    if (weeksToDelete.length > 0) {
        console.log("Identified documents to delete:", weeksToDelete.map(doc => doc.id));
        for (const doc of weeksToDelete) {
            console.log(`Deleting document: ${doc.id}`);
            await deleteDoc(doc.ref);
        }
    } else {
        console.log("No documents to delete, continuing with other operations...");
    }

    // // Main loop to copy each target week
    // for (const targetWeek of targetWeeks) {
    //     const targetPath = `${baseWeeksPath}${targetWeek}`;
    //     console.log(`Copying data to target week path: ${targetPath}`);
        // const targetDocRef = doc(firestore, targetPath);

        // Main loop to copy each target week
for (const targetWeek of targetWeeks) {
    const targetPath = `${baseWeeksPath}${targetWeek}`;
    console.log(`Copying data to target week path: ${targetPath}`);
    const targetDocRef = doc(firestore, targetPath);

    // Set the initial content for the target week document including the createdOn timestamp
    const initialData = {
        createdOn: Timestamp.now()  // Set the timestamp as part of the initial document setup
    };

    // You can merge this operation with any initial data that needs to be set for the document
    await setDoc(targetDocRef, initialData, { merge: true });
    console.log(`Initial document created for week ${targetWeek} with timestamp`);



        // Copy each day as a subcollection
        for (const [day, slots] of Object.entries(sourceWeek)) {
            if (Array.isArray(slots) && slots.length > 0) {
                // Process each slot in the day
                for (const slot of slots) {
                    const slotId = slot.id; // E.g., "0800-0900"
                    const slotData = slot.data;
                    const slotDocRef = doc(firestore, `${targetPath}/${day}/${slotId}`);
                    
                    // Prepare encounterTypes for Firestore
                    const encounterTypes = slotData.encounterTypes.map(type => {
                        const [key, value] = Object.entries(type)[0];
                        return { [key]: value }; // Convert to map
                    });

                    // Set document data
                    const documentData = {
                        startTime: slotData.startTime,
                        endTime: slotData.endTime,
                        maxRegularAppts: slotData.maxRegularAppts,
                        maxProcedureAppts: slotData.maxProcedureAppts,
                        encounterTypes: encounterTypes
                    };

                    await setDoc(slotDocRef, documentData);
                    console.log(`Document ${slotId} set in ${day} with data`, documentData);
                }
            }
        }
        console.log(`All data set for week ${targetWeek}`);
        alert(`All data copied to the ${targetWeek} week!`)
        setCopySelections({});
        setDialogContent([]);
    }
}


// --------------------------------------------------------------

// // Function to copy a single subcollection
// async function copySubcollection(sourceDocRef, targetDocRef, subcollectionName) {
//     const sourceSubcollectionRef = collection(sourceDocRef, subcollectionName);
//     const snapshot = await getDocs(sourceSubcollectionRef);
//     for (const doc of snapshot.docs) {  // Changed to for...of for proper async handling
//         const targetSubDocRef = doc(targetDocRef, subcollectionName, doc.id);
//         await setDoc(targetSubDocRef, doc.data());
//     }
// }

// async function copyWeekData(firestore, sourceWeek, userEmail, physician, selectedCalendarToEdit)  {
//     console.log("Starting copyWeekData function");

//     const sourceWeekId = fromWeek
//     console.log(sourceWeek)
//     console.log("Source week ID:", sourceWeekId);

//     const baseWeeksPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/`;
//     console.log(baseWeeksPath)
//     const sourceDocRef = doc(firestore, baseWeeksPath + sourceWeekId); // Correctly form the source document reference
//     const weeksRef = collection(firestore, baseWeeksPath);

//     console.log("Fetching all documents from weeks of month to check against target weeks");
//     const allWeeksDocs = await getDocs(weeksRef);
//     console.log("Documents fetched:", allWeeksDocs.docs.map(doc => doc.id));

//     const weeksToDelete = allWeeksDocs.docs.filter(doc => targetWeeks.includes(doc.id));
//     if (weeksToDelete.length > 0) {
//         console.log("Identified documents to delete:", weeksToDelete.map(doc => doc.id));
//         for (const doc of weeksToDelete) {
//             console.log(`Deleting document: ${doc.id}`);
//             await deleteDoc(doc.ref);
//         }
//     } else {
//         console.log("No documents to delete, continuing with other operations...");
//     }

//     // Main loop to copy each target week
//     for (const targetWeek of targetWeeks) {
//         const targetPath = `${baseWeeksPath}${targetWeek}`;
//         console.log(`Copying data to target week path: ${targetPath}`);
//         const targetDocRef = doc(firestore, targetPath);
//         try {
//             await setDoc(targetDocRef, sourceWeek);
//             console.log(`Document set for week ${targetWeek} with source data`);

//             // Handle subcollections
//             const subcollections = ['subcollection1', 'subcollection2']; // Customize as needed
//             for (const subcollectionName of subcollections) {
//                 await copySubcollection(sourceDocRef, targetDocRef, subcollectionName);
//             }
//         } catch (error) {
//             console.error('Error setting document:', error);
//         }
//     }
// }


// --------------------------------------------------------------


// async function copyWeekData(firestore, sourceWeek, userEmail, physician, selectedCalendarToEdit) {
//     console.log("Starting copyWeekData function");
//     console.log("Source week:", sourceWeek);

//     const sourceWeekId = fromWeek

//     const baseWeeksPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/`;
//     const weeksRef = collection(firestore, baseWeeksPath);
//     console.log("Base path for weeks of month set:", baseWeeksPath);

//     // Fetch all documents to check against targetWeeks
//     console.log("Fetching all documents from weeks of month to check against target weeks");
//     const allWeeksDocs = await getDocs(weeksRef);
//     console.log("Documents fetched:", allWeeksDocs.docs.map(doc => doc.id)); // Logging document IDs

// // Deleting existing documents that match any of the targetWeeks
// const weeksToDelete = allWeeksDocs.docs.filter(doc => targetWeeks.includes(doc.id));

// if (weeksToDelete.length > 0) {
//     console.log("Identified documents to delete:", weeksToDelete.map(doc => doc.id));
//     for (const doc of weeksToDelete) {
//         console.log(`Deleting document: ${doc.id}`);
//         await deleteDoc(doc.ref);
//         console.log(`Document deleted: ${doc.id}`);
//     }
// } else {
//     // Continue with the rest of the next functions if no documents to delete
//     console.log("No documents to delete, continuing with other operations...");
//     // Here you can call other functions or continue the execution flow
// }



// // Main loop to copy each target week
// for (const targetWeek of targetWeeks) {
//     const targetPath = `${baseWeeksPath}${targetWeek}`;
//     console.log(`Copying data to target week path: ${targetPath}`);
//     const targetDocRef = doc(firestore, targetPath);
//     try {
//         await setDoc(targetDocRef, sourceWeek);
//         console.log(`Document set for week ${targetWeek} with source data`);

//         // Handle subcollections
//         const subcollections = ['subcollection1', 'subcollection2']; // List subcollections here
//         for (const subcollectionName of subcollections) {
//             await copySubcollection(doc(firestore, baseWeeksPath + sourceWeekId), targetDocRef, subcollectionName);
//         }
//     } catch (error) {
//         console.error('Error setting document:', error);
//     }
// }
// }


// ------------------------------------------------------------------

    // // Create new document for each targetWeek and copy data
    // for (const targetWeek of targetWeeks) {
    //     const targetPath = `${baseWeeksPath}${targetWeek}`;
    //     console.log(`Copying data to target week path: ${targetPath}`);
    //     const targetDocRef = doc(firestore, targetPath);
    //     try {
    //         await setDoc(targetDocRef, sourceWeek);
    //         console.log(`Document set for week ${targetWeek} with source data`);
    //     } catch (error) {
    //         console.error('Error setting document:', error);
    //     }
    // }





// async function copyWeekData(firestore, sourceWeek, userEmail, physician, selectedCalendarToEdit, targetWeeks) {
//     console.log(sourceWeek)
//     for (const subcollection of subcollections) {
//         const sourcePath = `.../${sourceWeek}/${subcollection}`;
//         const documents = await fetchAllDocumentsFromSubcollection(firestore, sourcePath);
        
//         for (const targetWeek of targetWeeks) {
//             const targetPath = `.../${targetWeek}/${subcollection}`;
//             for (const doc of documents) {
//                 // Assuming 'doc' is a Firestore document snapshot
//                 const targetDocRef = doc(firestore, `${targetPath}/${doc.id}`);
//                 // Ensure to call data() method to get the document data
//                 await setDoc(targetDocRef, doc.data());
//             }
//         }
//     }
// }


const handleCopyWeek = async () => {
    setIsInProcess(true);
    console.log("Initiating week copy process...");
    setCopying(true);
    console.log(fromWeek)
    console.log(targetWeeks)

    
    const sourceWeekPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${fromWeek}`;
    const sourceData = await fetchWeekData(firestore, sourceWeekPath);

    // Filter out and ensure we have valid weeks selected
    const preTargetWeeks = Object.keys(copySelections).filter(week => copySelections[week]);
    console.log(preTargetWeeks)
    setTargetWeeks(preTargetWeeks)

    if (Array.isArray(preTargetWeeks) && preTargetWeeks.length > 0) {
        console.log(`Copying from ${fromWeek} to weeks: ${preTargetWeeks.join(', ')}`);
        await copyWeekData(firestore, sourceData, userEmail, physician, selectedCalendarToEdit);
    } else {
        console.error("No valid target weeks selected for copying.");
        setIsInProcess(false);
    }
    setShowCopyDialog(false);
    setCopying(false);
    setIsInProcess(false);

};


    return (
        <div>

            {weeksOfMonth.map((week, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <span>{`Week ${index + 1}: `}</span>
                        {daysOfWeek.map(day => (
                            <Button key={day} onClick={() => handleWeekDaySelection(week, day)}>
                                {day}
                            </Button>
                        ))}
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCopyDialogOpen(index)}
                        style={{
                            marginLeft: '5px', // Add some space between the switch and the button
                            height: '20px', // Example of a smaller height
                            fontSize: '0.75rem', // Smaller font size
                            padding: '0 6px', // Reduced padding
                            minHeight: '0', // Override Material-UI's minimum height
                        }}
                        >
                        {`Copy ${index + 1}${index === 0 ? 'st' : index === 1 ? 'nd' : index === 2 ? 'rd' : 'th'} Week To...`}
                    </Button>

                </div>
            ))}

            {/* Scrollable content */}
            <div style={{ maxHeight: '600px', overflowY: 'auto', border: '1px solid lightgray', marginTop: '10px' }}>
                {selectedWeekDay.week && selectedWeekDay.day && (
                    <WeekDayLimit
                        week={selectedWeekDay.week}
                        day={selectedWeekDay.day}
                        userEmail={userEmail}
                        physician={physician}
                        selectedCalendarToEdit={selectedCalendarToEdit}
                        firestore={firestore}
                        encounterTypes={encounterTypes}
                        weekDayLimits={weekDayLimits} 
                    />
                )}
            </div>

<BasicProgressOverlay
    isInProcess={isInProcess} 
  />

            {copying ? (
                <Dialog 
                open={true} 
                onClose={() => setShowCopyDialog(false)}
                sx={{ top: '30vh', position: 'absolute' }} // Adjust the top value as needed
                >
                <DialogTitle>{"Copying in Progress"}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                    Week(s) copying in process. Do not close, refresh, or reload the application. The process may take several minutes to complete and you may be required to press OK multiple times. Please be patient and wait for the process to finish.
                    </Typography>
                </DialogContent>
                </Dialog>

    
) : (
    showCopyDialog && (
        <Dialog open={showCopyDialog} onClose={() => setShowCopyDialog(false)}>
            <DialogTitle>{"Copy to Other Week(s)"}</DialogTitle>
            <DialogContent>
                {dialogContent.map((weekLabel, idx) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={copySelections[weekLabel] || false}
                                onChange={() => handleCopySelectionChange(weekLabel, idx)}
                            />
                        }
                        label={`Copy to ${weekLabel.charAt(0).toUpperCase() + weekLabel.slice(1)} Week`}
                        key={weekLabel}
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowCopyDialog(false)}>Cancel</Button>
                <Button onClick={handleCopyWeek}>Copy</Button>
            </DialogActions>
        </Dialog>
        
    )
)}


<div style={{ color: 'black', marginBottom: '20px', border: '1px solid black', padding: '10px', marginTop: '25px' }}>
  <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
    <li style={{ fontWeight: 'bold' }}>
      IMPORTANT RULES:
    </li>
    <li style={{ paddingLeft: '20px' }}>
      1) The smallest time range possible is 1 whole hour. 1 whole hour is defined as
      00:00 to 00:59 (e.g., if the time range is from 8 AM to 9 AM, it shall be written as
    </li>
    <li style={{ paddingLeft: '40px' }}>
      08:00 AM to 08:59 AM, also 08:30 AM to 09:30 AM will NOT work as is not a compatible whole hour!).
    </li>
    <li style={{ paddingLeft: '20px' }}>
      2) NEVER overlap time ranges! (e.g., a time range 08:00 AM to 08:59 AM, another time range 07:00 AM
      to 10:00 AM).
    </li>
    <li style={{ paddingLeft: '20px' }}>
      3) Time ranges CAN have multiple hours within (e.g., from 8 AM to 5 PM would need to be set
      as 08:00 AM to 04:59 PM).
    </li>
  </ul>
</div>


        </div>
    );
};

export default TabContentLimitsByEncounterType;




