import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';


const ProgressOverlay = ({ isLoading, itemsBeingProcessed, currentLoadedClients }) => {
  if (!isLoading) return null;

  const isComplete = itemsBeingProcessed === currentLoadedClients - 1;
  
  // Calculate the progress as a percentage
  const progressPercent = isComplete ? 100 : (itemsBeingProcessed / currentLoadedClients) * 100;
  

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1500,
    }}>
      <div style={{
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CircularProgress variant="determinate" value={progressPercent} />
      </div>
      <div style={{
        marginTop: '20px',
        color: 'white',
        textShadow: '1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black',
        fontSize: '20px',
      }}>
        {`${itemsBeingProcessed + 1} Client${(itemsBeingProcessed + 1) === 1 ? '' : 's'} Notified!`}
      </div>
    </div>
  );
};

export default ProgressOverlay;
