import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, FormControl, Grid, InputLabel, Select, MenuItem, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Typography, Collapse } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { doc, setDoc, deleteDoc, getDoc, collection, getDocs, Timestamp } from "firebase/firestore";
import moment from 'moment-timezone';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import BasicProgressOverlay from './components/BasicProgressOverlay'; 



const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);



const TimePickerInput = ({ label, selectedTime, onChange }) => {
    return (
        <Grid item xs={12} md={4}>
            <TextField
                label={label}
                type="time"
                fullWidth
                margin="normal"
                variant="outlined"
                value={selectedTime}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 min steps
            />
        </Grid>
    );
};

const TimeRange = ({ index, range, encounterTypes, onUpdate, onRemove }) => {
    const [localEncounterTypes, setLocalEncounterTypes] = useState([]);
    const [regularAppts, setRegularAppts] = useState('');
    const [procedureAppts, setProcedureAppts] = useState('');

    console.log("Encounter Types:", encounterTypes);
    console.log("Range:", range);

    useEffect(() => {
        console.log("Initial encounterTypes:", range.encounterTypes);
        const formattedEncounterTypes = range.encounterTypes.map((enc) => {
            if (Object.keys(enc).length === 1 && typeof Object.values(enc)[0] === 'number') {
                const type = Object.keys(enc)[0];
                const qty = enc[type];
                return { type, qty, id: enc.id || Math.random() };
            } else {
                return { ...enc, id: enc.id || Math.random() };
            }
        });
        console.log("Formatted for state: ", formattedEncounterTypes);
        setLocalEncounterTypes(formattedEncounterTypes);
    }, [range.encounterTypes]);

    useEffect(() => {
        let regularSum = 0;
        let procedureSum = 0;

        localEncounterTypes.forEach((encounter) => {
            const matchedType = encounterTypes.find(type => type.value.toLowerCase() === encounter.type.toLowerCase());
            if (matchedType) {
                if (matchedType.category === "Regular Appointment") {
                    regularSum += encounter.qty || 0;
                } else if (matchedType.category === "Procedure Appointment") {
                    procedureSum += encounter.qty || 0;
                }
            }
        });

        setRegularAppts(regularSum.toString());
        setProcedureAppts(procedureSum.toString());
    }, [localEncounterTypes, encounterTypes]);

    const handleAddEncounterType = () => {
        const newEncounterType = { type: '', qty: 1, id: Math.random() };
        setLocalEncounterTypes(prev => {
            const updated = [...prev, newEncounterType];
            onUpdate(index, 'encounterTypes', updated);
            return updated;
        });
    };

    const handleEncounterTypeChange = (encIndex, field, value) => {
        setLocalEncounterTypes(prev => {
            const updated = prev.map((enc, idx) => {
                if (idx === encIndex) {
                    return { ...enc, [field]: value };
                }
                return enc;
            });
            onUpdate(index, 'encounterTypes', updated);
            return updated;
        });
    };

    const handleRemoveEncounterType = (encIndex) => {
        setLocalEncounterTypes(prev => {
            const updated = prev.filter((_, idx) => idx !== encIndex);
            onUpdate(index, 'encounterTypes', updated);
            return updated;
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 4, fontWeight: 'bold' }}>
                    Time Range:
                </Typography>
                <TimePickerInput
                    label="Start Time"
                    selectedTime={range.startTime}
                    onChange={(e) => onUpdate(index, 'startTime', e.target.value)}
                />
                <TimePickerInput
                    label="End Time"
                    selectedTime={range.endTime}
                    onChange={(e) => onUpdate(index, 'endTime', e.target.value)}
                />
                <Button variant="contained" onClick={handleAddEncounterType} sx={{ height: '40px', minWidth: 'auto', ml: 2 }}>
                    Add Encounter Type
                </Button>
                <Tooltip title="Delete Time Range">
                    <IconButton onClick={() => onRemove(index)} color="error" sx={{ ml: 1 }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'black' }}>
                        Regular Appts:
                    </Typography>
                    <TextField
                        size="small"
                        value={regularAppts}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'black' }}>
                        Procedure Appts:
                    </Typography>
                    <TextField
                        size="small"
                        value={procedureAppts}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>
            </Box>
            {localEncounterTypes.map((encounter, encIndex) => (
                <Box key={encounter.id} sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 7 }}>
                    <FormControl fullWidth sx={{ minWidth: 120 }}>
                        <InputLabel>Encounter Type</InputLabel>
                        <Select
                            value={encounter.type}
                            onChange={(e) => handleEncounterTypeChange(encIndex, 'type', e.target.value)}
                            label="Encounter Type"
                            renderValue={(selected) => {
                                const matchedType = encounterTypes.find(type => type.value.toLowerCase() === selected.toLowerCase());
                                const isMatched = Boolean(matchedType);
                                return (
                                    <Typography style={{ color: isMatched ? 'inherit' : 'red' }}>
                                        {isMatched ? matchedType.label : selected}
                                    </Typography>
                                );
                            }}
                            sx={{ height: '40px', bgcolor: 'background.paper' }}
                        >
                            {encounterTypes.map(type => (
                                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Qty"
                        type="number"
                        value={(encounter.qty || 0).toString()}
                        onChange={(e) => handleEncounterTypeChange(encIndex, 'qty', parseInt(e.target.value, 10))}
                        sx={{ width: '100px', height: '40px', '& .MuiInputBase-input': { py: '10px' } }}
                    />
                    <Tooltip title="Remove Encounter Type">
                        <IconButton onClick={() => handleRemoveEncounterType(encIndex)} color="error">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ))}
        </Box>
    );
};

// const TimeRange = ({ index, range, encounterTypes, onUpdate, onRemove }) => {
//     const [localEncounterTypes, setLocalEncounterTypes] = useState([]);
//     const [regularAppts, setRegularAppts] = useState('');
//     const [procedureAppts, setProcedureAppts] = useState('');

//     console.log("Encounter Types:", encounterTypes);
//     console.log("Range:", range);


//     // console.log(localEncounterTypes)

//     useEffect(() => {
//         console.log("Initial encounterTypes:", range.encounterTypes);
//         const formattedEncounterTypes = range.encounterTypes.map((enc) => {
//             if (Object.keys(enc).length === 1 && typeof Object.values(enc)[0] === 'number') {
//                 const type = Object.keys(enc)[0];
//                 const qty = enc[type];
//                 return { type, qty, id: enc.id || Math.random() };
//             } else {
//                 return { ...enc, id: enc.id || Math.random() };
//             }
//         });
//         console.log("Formatted for state: ", formattedEncounterTypes);
//         setLocalEncounterTypes(formattedEncounterTypes);
//     }, [range.encounterTypes]);

//     const handleAddEncounterType = () => {
//         const newEncounterType = { type: '', qty: 1, id: Math.random() };
//         setLocalEncounterTypes(prev => {
//             const updated = [...prev, newEncounterType];
//             onUpdate(index, 'encounterTypes', updated);
//             return updated;
//         });
//     };

//     const handleEncounterTypeChange = (encIndex, field, value) => {
//         setLocalEncounterTypes(prev => {
//             const updated = prev.map((enc, idx) => {
//                 if (idx === encIndex) {
//                     return { ...enc, [field]: value };
//                 }
//                 return enc;
//             });
//             onUpdate(index, 'encounterTypes', updated);
//             return updated;
//         });
//     };

//     const handleRemoveEncounterType = (encIndex) => {
//         setLocalEncounterTypes(prev => {
//             const updated = prev.filter((_, idx) => idx !== encIndex);
//             onUpdate(index, 'encounterTypes', updated);
//             return updated;
//         });
//     };

//     return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
// <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
//     <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 4, fontWeight: 'bold' }}>
//         Time Range:
//     </Typography>
//     <TimePickerInput
//         label="Start Time"
//         selectedTime={range.startTime}
//         onChange={(e) => onUpdate(index, 'startTime', e.target.value)}
//     />
//     <TimePickerInput
//         label="End Time"
//         selectedTime={range.endTime}
//         onChange={(e) => onUpdate(index, 'endTime', e.target.value)}
//     />
//     <Button variant="contained" onClick={handleAddEncounterType} sx={{ height: '40px', minWidth: 'auto', ml: 2 }}>
//         Add Encounter Type
//     </Button>
//     <Tooltip title="Delete Time Range">
//         <IconButton onClick={() => onRemove(index)} color="error" sx={{ ml: 1 }}>
//             <DeleteIcon />
//         </IconButton>
//     </Tooltip>
//     <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
//         <Typography variant="body2" sx={{ color: 'black' }}>
//             Regular Appts:
//         </Typography>
//         <TextField
//             size="small"
//             value={regularAppts}
//             InputProps={{
//                 readOnly: true,
//             }}
//         />
//     </Box>
//     <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
//         <Typography variant="body2" sx={{ color: 'black' }}>
//             Procedure Appts:
//         </Typography>
//         <TextField
//             size="small"
//             value={procedureAppts}
//             InputProps={{
//                 readOnly: true,
//             }}
//         />
//     </Box>
// </Box>
//             {localEncounterTypes.map((encounter, encIndex) => (
//                 <Box key={encounter.id} sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 7 }}>
//                     <FormControl fullWidth sx={{ minWidth: 120 }}>
//                         <InputLabel>Encounter Type</InputLabel>
//                         <Select
//     value={encounter.type}
//     onChange={(e) => handleEncounterTypeChange(encIndex, 'type', e.target.value)}
//     label="Encounter Type"
//     renderValue={(selected) => {
//         // Find the matched type object using a case-insensitive comparison
//         const matchedType = encounterTypes.find(type => type.value.toLowerCase() === selected.toLowerCase());
//         const isMatched = Boolean(matchedType);

//         // Debugging output to trace values
//         // console.log("Selected:", selected, "Matched:", isMatched, "Display:", matchedType ? matchedType.label : selected);

//         return (
//             <Typography style={{ color: isMatched ? 'inherit' : 'red' }}>
//                 {isMatched ? matchedType.label : selected}
//             </Typography>
//         );
//     }}
//     sx={{ height: '40px', bgcolor: 'background.paper' }}
// >
//     {encounterTypes.map(type => (
//         <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
//     ))}
// </Select>



//                     </FormControl>
//                     <TextField
//                         label="Qty"
//                         type="number"
//                         value={(encounter.qty || 0).toString()}
//                         onChange={(e) => handleEncounterTypeChange(encIndex, 'qty', parseInt(e.target.value, 10))}
//                         sx={{ width: '100px', height: '40px', '& .MuiInputBase-input': { py: '10px' } }}
//                     />
//                     <Tooltip title="Remove Encounter Type">
//                         <IconButton onClick={() => handleRemoveEncounterType(encIndex)} color="error">
//                             <DeleteIcon />
//                         </IconButton>
//                     </Tooltip>
//                 </Box>
//             ))}
//         </Box>
//     );
// };


const WeekDayLimit = ({ week, day, userEmail, physician, selectedCalendarToEdit, firestore, encounterTypes, weekDayLimits }) => {
    const [timeRanges, setTimeRanges] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [open, setOpen] = useState(false);
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [availableDays, setAvailableDays] = useState([]);
    const [selectedDays, setSelectedDays] = useState({});
    const [isInProcess, setIsInProcess] = useState(false);

    useEffect(() => {
        console.log(timeRanges)
    }, [timeRanges]);

// Initialize timeRanges state with weekDayLimits if available
        useEffect(() => {
            setTimeRanges(weekDayLimits || [{ startTime: '08:00', endTime: '09:00', encounterTypes: [{ type: '', qty: 0 }] }]);
        }, [weekDayLimits]);

    const handleAddTimeRange = () => {
        setTimeRanges([...timeRanges, { startTime: '', endTime: '', encounterTypes: [{ type: '', qty: 0 }] }]);
    };

    const handleTimeRangeChange = (index, field, value) => {
        const updatedTimeRanges = [...timeRanges];
        if (field === "startTime" || field === "endTime") {
            updatedTimeRanges[index][field] = value;
        } else if (field === "encounterTypes") {
            updatedTimeRanges[index].encounterTypes = value;
        }
        setTimeRanges(updatedTimeRanges);
    };
    

    const handleDeleteTimeRange = (index) => {
        setTimeRanges(timeRanges.filter((_, i) => i !== index));
    };


    const handleSave = async () => {
        setIsInProcess(true);
        console.log("Saving...");
        const dayName = day;  // E.g., 'monday'
        const dayDocPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${day}`;
        const weekPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}`;
    
        try {
            // Step 1: Delete existing time range documents for the given day
            const dayRef = collection(firestore, dayDocPath);
            const snapshot = await getDocs(dayRef);
            const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
            console.log(`Deleted existing time ranges for day: ${dayName}`);
    
            console.log(`Copying data to target week path: ${weekPath}`);
            const targetDocRef = doc(firestore, weekPath);
            
            // Check if the document at weekPath already exists
            const docSnap = await getDoc(targetDocRef);
            
            if (!docSnap.exists()) {
                console.log(`No existing document found at ${weekPath}, creating new document.`);
                const initialData = {
                    createdOn: Timestamp.now()
                };
                await setDoc(targetDocRef, initialData, { merge: true });
                console.log(`Initial document created for week ${weekPath} with timestamp`);
            } else {
                console.log(`Document already exists at ${weekPath}, skipping creation.`);
            }
                    
            // Step 2: Save each new time range
            for (const range of timeRanges) {
                const docId = `${range.startTime.replace(':', '')}-${range.endTime.replace(':', '')}`;
                console.log(`Range Document ID: ${docId}`);
                console.log(range.encounterTypes)
    
                const docRef = doc(firestore, dayDocPath, docId);

                // const encounterTypesArray = range.encounterTypes.map(encounter => {
                //     const [type, qty] = Object.entries(encounter)[0];
                //     console.log("Encounter type:", type, "Quantity:", qty);
                
                //     // Ensure qty is not NaN, set to 0 if it is
                //     let validQty = isNaN(parseInt(qty, 10)) ? 0 : parseInt(qty, 10);
                
                //     let obj = {};
                //     obj[encounter.type || type] = validQty;
                //     return obj;
                // });
                


                const encounterTypesArray = range.encounterTypes.map(encounter => {
                    let [type, qty] = Object.entries(encounter)[0];

                    if (isNaN(qty)) {
                        qty = 0;
                    }
                    console.log("Encounter type:", type, "Quantity:", qty);
                    let obj = {};
                    obj[encounter.type || type] = parseInt(encounter.qty || qty, 10);
                    return obj;
                });
                
                console.log("Final encounterTypesArray to be saved:", encounterTypesArray);
                
                // Calculate maxRegularAppts and maxProcedureAppts
                let maxRegularAppts = 0;
                let maxProcedureAppts = 0;

                encounterTypesArray.forEach(encounter => {
                    const type = Object.keys(encounter)[0];
                    let qty = encounter[type];
                    
                    // Ensure qty is not NaN, set to 0 if it is
                    if (isNaN(qty)) {
                        qty = 0;
                    }
                
                    const matchedType = encounterTypes.find(et => et.value.toLowerCase() === type.toLowerCase());
                    if (matchedType) {
                        if (matchedType.category === "Regular Appointment") {
                            maxRegularAppts += qty;
                        } else if (matchedType.category === "Procedure Appointment") {
                            maxProcedureAppts += qty;
                        }
                    }
                });
                
                
                // encounterTypesArray.forEach(encounter => {
                //     const type = Object.keys(encounter)[0];
                //     const qty = encounter[type];
                //     const matchedType = encounterTypes.find(et => et.value.toLowerCase() === type.toLowerCase());
                //     if (matchedType) {
                //         if (matchedType.category === "Regular Appointment") {
                //             maxRegularAppts += qty;
                //         } else if (matchedType.category === "Procedure Appointment") {
                //             maxProcedureAppts += qty;
                //         }
                //     }
                // });
    
                const docData = {
                    startTime: range.startTime,
                    endTime: range.endTime,
                    encounterTypes: encounterTypesArray,
                    maxRegularAppts: maxRegularAppts,
                    maxProcedureAppts: maxProcedureAppts
                };
    
                // Save the document
                await setDoc(docRef, docData);
                console.log(`Document successfully written for day: ${dayName} with ID: ${docId}`);
                console.log(encounterTypesArray);
                console.log(`Max Regular Appts: ${maxRegularAppts}, Max Procedure Appts: ${maxProcedureAppts}`);
            }
    
            alert(`All time ranges successfully saved for: ${dayName}`);
            setIsInProcess(false);
    
        } catch (error) {
            console.error("Error processing document:", error);
            alert(`Error saving time ranges for ${dayName}. Please try again.`);
            setIsInProcess(false);
        }
    };


    // const handleSave = async () => {
    //     console.log("Saving...");
    //     const dayName = day;  // E.g., 'monday'
    //     const dayDocPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${day}`;
    //     const weekPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}`;
    
    //     try {
    //         // Step 1: Delete existing time range documents for the given day
    //         const dayRef = collection(firestore, dayDocPath);
    //         const snapshot = await getDocs(dayRef);
    //         // Delete each document found for the day
    //         const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
    //         await Promise.all(deletePromises);
    //         console.log(`Deleted existing time ranges for day: ${dayName}`);
    
    //         console.log(`Copying data to target week path: ${weekPath}`);
    //         const targetDocRef = doc(firestore, weekPath);
            
    //         // First, check if the document at weekPath already exists
    //         const docSnap = await getDoc(targetDocRef);
            
    //         if (!docSnap.exists()) {
    //             // Document does not exist, proceed with creation
    //             console.log(`No existing document found at ${weekPath}, creating new document.`);
            
    //             const initialData = {
    //                 createdOn: Timestamp.now()  // Set the timestamp as part of the initial document setup
    //             };
            
    //             // You can merge this operation with any initial data that needs to be set for the document
    //             await setDoc(targetDocRef, initialData, { merge: true });
    //             console.log(`Initial document created for week ${weekPath} with timestamp`);
    //         } else {
    //             console.log(`Document already exists at ${weekPath}, skipping creation.`);
    //         }
                    
    //         // Step 2: Save each new time range
    //         for (const range of timeRanges) {
    //             const docId = `${range.startTime.replace(':', '')}-${range.endTime.replace(':', '')}`;
    //             console.log(`Range Document ID: ${docId}`);
    //             console.log(range.encounterTypes)
    
    //             const docRef = doc(firestore, dayDocPath, docId);
    
    //             const encounterTypesArray = range.encounterTypes.map(encounter => {
    //                 // Since each object has only one key-value pair, extract the first (and only) entry
    //                 const [type, qty] = Object.entries(encounter)[0];
                
    //                 console.log("Encounter type:", type, "Quantity:", qty);
                
    //                 // Ensure qty is saved as a number, although it should already be a number based on your log
    //                 let obj = {};
    //                 obj[encounter.type || type] = parseInt(encounter.qty || qty, 10); // This is likely redundant but safe
                
    //                 return obj;
    //             });
                
    //             // Further debug to see the final array being sent to Firebase
    //             console.log("Final encounterTypesArray to be saved:", encounterTypesArray);
                
    //             const docData = {
    //                 startTime: range.startTime,
    //                 endTime: range.endTime,
    //                 encounterTypes: encounterTypesArray
    //             };
    
    //             // Save the document
    //             await setDoc(docRef, docData);
    //             console.log(`Document successfully written for day: ${dayName} with ID: ${docId}`);
    //             console.log(encounterTypesArray);
    //         }
    
    //         // Add alert after all time ranges have been saved successfully
    //         alert(`All time ranges successfully saved for: ${dayName}`);
    
    //     } catch (error) {
    //         console.error("Error processing document:", error);
    //         // Optionally, you can add an alert for errors as well
    //         alert(`Error saving time ranges for ${dayName}. Please try again.`);
    //     }
    // };


    const handleDeleteAll = async () => {
        setOpen(true); // Open confirmation dialog
    };

    // const confirmDeleteAll = async () => {
    //     const docRef = doc(firestore, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/${week}/${day}`);
    //     try {
    //         await deleteDoc(docRef);
    //         setTimeRanges([]);
    //         setOpen(false); // Close confirmation dialog
    //         alert("All time ranges deleted successfully.");
    //     } catch (error) {
    //         console.error("Error deleting all time ranges:", error);
    //     }
    // };

    const confirmDeleteAll = async () => {
        setIsInProcess(true);
        const dayRef = collection(firestore, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${day}`);
        try {
            // Get all documents from the subcollection
            const snapshot = await getDocs(dayRef);
            const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
            console.log(`All documents in the collection for day: ${day} have been deleted.`);
    
            setTimeRanges([]); // Clears the time ranges for the UI
            alert(`All documents in the collection for day: ${day} have been deleted.`);
            setOpen(false); // Close confirmation dialog
            setIsInProcess(false);
            
        } catch (error) {
            console.error("Error deleting all time ranges:", error);
            setIsInProcess(false);
        }
    };
    


    const handleCopyOpen = () => {
        // Filter out the current day and set the available days
        const filteredDays = daysOfWeek.filter(d => d !== day.toLowerCase());
        setAvailableDays(filteredDays);
        setCopyDialogOpen(true);
    };

// Function to fetch all documents from a specified day subcollection
const fetchDataFromDay = async (firestore, userEmail, physician, selectedCalendarToEdit, week, day) => {
    const dayCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${day}`;
    const dayCollectionRef = collection(firestore, dayCollectionPath);
    const querySnapshot = await getDocs(dayCollectionRef);
    const documents = [];
    if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
            documents.push({ id: doc.id, data: doc.data() });
        });
    } else {
        console.log(`No documents found in subcollection '${day}'.`);
    }
    return documents;  // Returns an array of documents with their data
};


//   // Function to copy data to other days
// const copyDataToOtherDays = async (firestore, userEmail, physician, selectedCalendarToEdit, week, sourceData, targetDays) => {
//     const writePromises = targetDays.map(async (day) => {
//       const targetDocPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${day}`;
//       const targetDocRef = doc(firestore, targetDocPath);
//       await setDoc(targetDocRef, sourceData, { merge: true }); // Use merge to avoid overwriting existing fields not included in sourceData
//     });
//     await Promise.all(writePromises);
//   };



// Function to copy documents from one day's subcollection to another
const copyDataToOtherDays = async (firestore, userEmail, physician, selectedCalendarToEdit, week, sourceDay, targetDays) => {
    setIsInProcess(true);
    console.log("Starting the document copy process...");
    // console.log(targetDays)
    // Define the source collection path
    const sourceCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${sourceDay}`;
    console.log(`Source collection path: ${sourceCollectionPath}`);
    const sourceCollectionRef = collection(firestore, sourceCollectionPath);

    // Get all documents from the source collection
    console.log(`Fetching documents from source collection: ${sourceDay}`);
    const querySnapshot = await getDocs(sourceCollectionRef);
    if (querySnapshot.empty) {
        console.log(`No documents found in '${sourceDay}'. Nothing to copy.`);
        return;
    }

    console.log(`Found ${querySnapshot.size} documents in '${sourceDay}'. Proceeding to copy to target days.`);
    // Iterate over each target day and copy each document to the corresponding collection
    for (const targetDay of targetDays) {
        const targetCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/general/weeksOfMonth/${week}/${targetDay}`;
        console.log(`Target collection path for '${targetDay}': ${targetCollectionPath}`);
        const targetCollectionRef = collection(firestore, targetCollectionPath);

        querySnapshot.forEach(async (docSnapshot) => {
            const targetDocRef = doc(targetCollectionRef, docSnapshot.id); // Ensures the document id is the same
            console.log(`Copying document ID '${docSnapshot.id}' to '${targetDay}'...`);
            try {
                await setDoc(targetDocRef, docSnapshot.data(), { merge: false }); // Optional merge true to combine data if exists
                console.log(`Document ID '${docSnapshot.id}' successfully copied to '${targetDay}'.`);
            } catch (error) {
                console.error(`Error copying document ID '${docSnapshot.id}' to '${targetDay}':`, error);
            }
        });
    }
    console.log(`Documents successfully copied from '${sourceDay}' to '${targetDays.join(', ')}'.`);
    setIsInProcess(false);
};


//   const handleCopyToSelectedDays = async () => {
//     try {
//       const sourceData = await fetchDataFromDay(firestore, userEmail, physician, selectedCalendarToEdit, week, day);
//       if (sourceData) {
//         await copyDataToOtherDays(firestore, userEmail, physician, selectedCalendarToEdit, week, sourceData, selectedDays);
//         console.log("Data copied successfully!");
//       } else {
//         console.log("No data found in the source day.");
//       }
//     } catch (error) {
//       console.error("Error copying data:", error);
//     }
//   };

const handleCopyToSelectedDays = async () => {
    setIsInProcess(true);
    try {
        const sourceData = await fetchDataFromDay(firestore, userEmail, physician, selectedCalendarToEdit, week, day);
        if (sourceData) {
            // Assume selectedDays is an object with days as keys and boolean as values
            const targetDays = Object.keys(selectedDays).filter(day => selectedDays[day]);
            if (targetDays.length > 0) {
                await copyDataToOtherDays(firestore, userEmail, physician, selectedCalendarToEdit, week, day, targetDays);
                console.log("Data copied successfully!");
                alert("Day data copied successfully!")
                setIsInProcess(false);
            } else {
                console.log("No target days selected.");
                setIsInProcess(false);
            }
        } else {
            console.log("No data found in the source day.");
            setIsInProcess(false);
        }
    } catch (error) {
        console.error("Error copying data:", error);
        alert("Error copying data:", error)
        setIsInProcess(false);
    }
};


  
  const handleCopySelectionChange = (day) => {
    setSelectedDays(prevDays => ({
      ...prevDays,
      [day]: !prevDays[day]
    }));
  };
  
  // When constructing the dialog, ensure you have checkboxes to manage selection:
  {availableDays.map((day) => (
    <FormControlLabel
      key={day}
      control={
        <Checkbox
          checked={selectedDays[day] || false}
          onChange={() => handleCopySelectionChange(day)}
        />
      }
      label={capitalize(day)}
    />
  ))}
  


    return (
        <Box sx={{ mt: 2 }}>
            <Box display="flex" alignItems="center">
                <Typography variant="h6" style={{ marginRight: '10px' }}>
                    {`Editing --> ${capitalize(day)} of the ${capitalize(week)} Week`}
                </Typography>
                <Button 
                    variant="contained" 
                    style={{ backgroundColor: '#1976d2', color: 'white' }}
                    onClick={handleCopyOpen}
                >
                    {`Copy ${capitalize(day)} To...`}
                </Button>
            </Box>
            <Button onClick={() => setExpanded(!expanded)} startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {expanded ? 'Hide' : 'Show'}
            </Button>
            <Collapse in={expanded}>
                {timeRanges.map((range, index) => (
                    <TimeRange
                        key={index}
                        index={index}
                        range={range}
                        encounterTypes={encounterTypes}
                        onUpdate={handleTimeRangeChange}
                        onRemove={() => handleDeleteTimeRange(index)}
                    />
                ))}
                <Button onClick={handleAddTimeRange} variant="contained" sx={{ mt: 2 }}>Add Time Range</Button>
                <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>Save</Button>
                <Button onClick={() => setOpen(true)} variant="outlined" color="error" sx={{ mt: 2, ml: 2 }}>Delete All</Button>
            </Collapse>

           {/* Copy Day Dialog */}
<Dialog open={copyDialogOpen} onClose={() => setCopyDialogOpen(false)}>
    <DialogTitle>{"Copy to Other Day(s)"}</DialogTitle>
    <DialogContent>
        {availableDays.map((day) => (
            <FormControlLabel
                key={day}
                control={
                    <Checkbox
                        checked={selectedDays[day] || false}
                        onChange={() => {
                            setSelectedDays(prev => ({
                                ...prev,
                                [day]: !prev[day]
                            }));
                        }}
                    />
                }
                label={capitalize(day)}
            />
        ))}
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setCopyDialogOpen(false)} color="primary">
            Cancel
        </Button>
        <Button 
            onClick={() => {
                handleCopyToSelectedDays();
                setCopyDialogOpen(false);
                setAvailableDays([]);
                setSelectedDays({});
            }} 
            color="primary" 
            variant="contained"
        >
            Copy
        </Button>
    </DialogActions>
</Dialog>


<BasicProgressOverlay
    isInProcess={isInProcess} 
  />


            {/* Confirmation Dialog for Deletion */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{"Confirm Delete All"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete all time ranges for this day?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={confirmDeleteAll} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>



    );

    
    // return (
    //     <Box sx={{ mt: 2 }}>
    //             <Box display="flex" alignItems="center">
    //             <Typography variant="h6" style={{ marginRight: '10px' }}>
    //                 {`Editing --> ${capitalize(day)} of the ${capitalize(week)} Week`}
    //             </Typography>
    //             <Button 
    //                 variant="contained" 
    //                 style={{ backgroundColor: '#1976d2', color: 'white' }}
    //                 onClick={() => console.log(`Copy ${capitalize(day)} To... clicked`)}
    //             >
    //                 {`Copy ${capitalize(day)} To...`}
    //             </Button>
    //             </Box>
    //         <Button onClick={() => setExpanded(!expanded)} startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>{expanded ? 'Hide' : 'Show'}</Button>
    //         <Collapse in={expanded}>
    //             {/* Map through timeRanges to render TimeRange components */}
    //             {timeRanges.map((range, index) => (

    //                 <TimeRange
    //                     key={index}
    //                     index={index}
    //                     range={range}
    //                     encounterTypes={encounterTypes}
    //                     onUpdate={handleTimeRangeChange}
    //                     onRemove={() => handleDeleteTimeRange(index)}
    //                 />


    //             ))}
    //             <Button onClick={handleAddTimeRange} variant="contained" sx={{ mt: 2 }}>Add Time Range</Button>
    //             <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>Save</Button>
    //             <Button onClick={() => setOpen(true)} variant="outlined" color="error" sx={{ mt: 2, ml: 2 }}>Delete All</Button>
    //         </Collapse>

    //         {/* Confirmation Dialog */}
    //         <Dialog open={open} onClose={() => setOpen(false)}>
    //             <DialogTitle>{"Confirm Delete All"}</DialogTitle>
    //             <DialogContent>
    //                 <DialogContentText>
    //                     Are you sure you want to delete all time ranges for this day?
    //                 </DialogContentText>
    //             </DialogContent>
    //             <DialogActions>
    //                 <Button onClick={() => setOpen(false)}>Cancel</Button>
    //                 <Button onClick={confirmDeleteAll} color="error">Delete</Button>
    //             </DialogActions>
    //         </Dialog>
    //     </Box>
    // );
};

export default WeekDayLimit;
