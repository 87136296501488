import React, { useState, useEffect } from 'react';
import './RegisteredClientsModal.css';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function RegisteredClientsModal({ open, onClose, accountEmail, providerName }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    

    const handleSearchChange = async (e) => {
        setSearchTerm(e.target.value);
      
        if (e.target.value.trim() !== '') {
          try {
            const response = await fetch(`https://nproapiserver.herokuapp.com/searchClients?term=${e.target.value}&accountEmail=${accountEmail}&providerName=${providerName}`);
            const data = await response.json();
            setSearchResults(data);
            // console.log(data)
          } catch (error) {
            console.error("Error fetching search results", error);
          }
        } else {
          setSearchResults([]);
        }
    }

    const handleClientSelect = (client) => {
        setSelectedClient(client);
        // console.log(client)
        setIsDetailModalOpen(true);
        setSearchTerm('');
        setSearchResults([]);
    }

    return (
        open && (
            <div className="modal-background">
                { isDetailModalOpen 
                    ? <DetailModal client={selectedClient} onClose={() => setIsDetailModalOpen(false)} accountEmail={accountEmail} providerName={providerName} />
                    : (
                        <div className="modal-content">
                            <Tooltip title="Close">
                                <CancelIcon className="close-icon" onClick={onClose} />
                            </Tooltip>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h2>Registered Clients Search</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Search..." />
                                </Grid>
                                <Grid item xs={12}>
                                <div className="search-results">
                                {searchResults.map(result => (
                                    <div key={`${result.phoneNumber}-${result.dateOfBirth}`} onClick={() => handleClientSelect(result)}>
                                    {result.firstName} {result.lastName} - DOB: {result.dateOfBirth} - Tel: {result.phoneNumber}
                                    </div>
                                ))}
                                </div>

                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </div>
        )
    );
}

function DetailModal({ client, onClose, accountEmail, providerName }) {

    const [formData, setFormData] = useState(client);
    const [originalData, setOriginalData] = useState(client);
    const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);

    useEffect(() => {
        // On load, save the original data for comparison
        setOriginalData(client);
        setFormData(client); // Reset form data on load
    }, [client]);

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
    };

    // Check if any field except for `dateOfBirth` has changed
    useEffect(() => {
        if (
            formData.firstName !== originalData.firstName ||
            formData.lastName !== originalData.lastName ||
            formData.email !== originalData.email ||
            formData.optIn !== originalData.optIn ||
            formData.otherInfo !== originalData.otherInfo
        ) {
            setIsUpdateEnabled(true); // Enable update button
        } else {
            setIsUpdateEnabled(false); // Disable update button if nothing has changed
        }
    }, [formData, originalData]);

    
    const handleUpdateDOB = async () => {
        const newDateOfBirth = formData.dateOfBirth; // The date already picked
    
        try {
            const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientDOB`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    oldDateOfBirth: client.dateOfBirth,
                    newDateOfBirth,
                    accountEmail,
                    providerName,
                    phoneNumber: formData.phoneNumber, // Needed to identify the client document
                    clientData: formData
                })
            });
            
            if (response.ok) {
                alert('Date of birth updated successfully!');
                setFormData({ ...formData, dateOfBirth: newDateOfBirth });
                onClose();
            } else {
                throw new Error('Failed to update date of birth');
            }
        } catch (error) {
            console.error("Error updating date of birth", error);
        }
    };
    

    const handleUpdatePhone = async () => {
        const newPhoneNumber = prompt('Enter new phone number (e.g., 1787XXXXXXX):', '');
        if (!newPhoneNumber || !/^\d{11}$/.test(newPhoneNumber)) {
            alert('Invalid phone number format.');
            return;
        }

        try {
            const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientPhoneNumber`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    oldPhoneNumber: formData.phoneNumber,
                    newPhoneNumber,
                    accountEmail,
                    providerName,
                    dateOfBirth: formData.dateOfBirth,
                    clientData: formData
                })
            });
            
            if (response.ok) {
                alert('Phone number updated successfully!');
                setFormData({ ...formData, phoneNumber: newPhoneNumber });
                onClose();
            } else {
                throw new Error('Failed to update phone number');
            }
        } catch (error) {
            console.error("Error updating phone number", error);
        }
    };

    const handleUpdate = async () => {
        const dateOfBirth= formData.dateOfBirth
        try {
            // Sending an update request to your server with accountEmail as a query param
            const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientDetailsByFrontEnd?phoneNumber=${client.phoneNumber}&accountEmail=${accountEmail}&providerName=${providerName}&dateOfBirth=${dateOfBirth}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });
            
            if (response.ok) {
                alert('Client details updated successfully!');
            } else {
                throw new Error('Failed to update client details');
            }
        } catch (error) {
            console.error("Error updating client details", error);
        }
    };
    
    const handleDelete = async () => {
        const dateOfBirth= formData.dateOfBirth
        const isConfirmed = window.confirm('Are you sure you want to delete this client?');
        if (isConfirmed) {
            try {
                // Sending a delete request to your server with accountEmail as a query param
                const response = await fetch(`https://nproapiserver.herokuapp.com/deleteClientByFrontEnd?phoneNumber=${client.phoneNumber}&accountEmail=${accountEmail}&providerName=${providerName}&dateOfBirth=${dateOfBirth}`, {
                    method: 'DELETE'
                });
    
                if (response.ok) {
                    onClose(); // close the modal
                    alert('Client deleted successfully!');
                } else {
                    throw new Error('Failed to delete client');
                }
            } catch (error) {
                console.error("Error deleting client", error);
            }
        }
    };
    
    return (
        <div className="modal-content">
            <Tooltip title="Close">
                <CancelIcon className="close-icon" onClick={onClose} />
            </Tooltip>
            <h2>Client Details</h2>
            <Grid container spacing={2} className="client-details-form">
                <Grid item xs={12} sm={4}>
                    <label>Phone Number:</label>
                </Grid>
                <Grid item xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                    {formData.phoneNumber}
                    <Tooltip title="Update Phone Number">
                        <button 
                            className="update-phone-button" 
                            onClick={handleUpdatePhone} 
                            style={{ marginLeft: '109px' }}
                        >
                            <strong>Update Phone</strong>
                        </button>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>First Name:</label>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <input name="firstName" value={formData.firstName} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>Last Name:</label>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <input name="lastName" value={formData.lastName} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
        <label htmlFor="dateOfBirth">Date of Birth:</label>
    </Grid>
                <Grid item xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
    <input 
        id="dateOfBirth"
        type="date"
        name="dateOfBirth" 
        value={formData.dateOfBirth}
        onChange={handleChange}
        style={{ width: '43.7%', height: '5px', padding: '10px', boxSizing: 'border-box' }}
    />
    <Tooltip title="Update Date of Birth">
        <button 
            className="update-phone-button"  
            onClick={handleUpdateDOB} 
            style={{ marginLeft: '30px' }}
        >
            <strong>Update DOB</strong>
        </button>
    </Tooltip>
</Grid>

                {/* <Grid item xs={12} sm={4}>
        <label htmlFor="dateOfBirth">Date of Birth:</label>
    </Grid>
    <Grid item xs={12} sm={8}>
        <input 
            id="dateOfBirth"
            type="date"
            name="dateOfBirth" 
            value={formData.dateOfBirth}
            onChange={handleChange}
            style={{ width: '68.25%', height: '5px', padding: '10px', boxSizing: 'border-box' }}
        />
    </Grid> */}
                <Grid item xs={12} sm={4}>
                    <label>Email:</label>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <input name="email" value={formData.email} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <label>App Notifications:</label>
                </Grid>
                <Grid item xs={12} sm={8}>
    <FormControlLabel
        control={
            <Checkbox 
                name="optIn" 
                checked={formData.optIn} 
                onChange={handleChange} 
                color="primary"
            />
        }
        label="Opt In"
    />
</Grid>
<Grid item xs={12} sm={4}>
    <label htmlFor="otherInfo">Other Info:</label>
</Grid>
<Grid item xs={12}>
    <textarea
        id="otherInfo"
        name="otherInfo" 
        value={formData.otherInfo} 
        onChange={handleChange}
        rows={3}  // Sets the initial number of rows
        // placeholder="Other Info"
        style={{ 
            width: '100%', 
            height: '80px',  // Fixed height
            padding: '10px', 
            boxSizing: 'border-box', 
            marginBottom: '20px', 
            borderRadius: '4px', 
            border: '1px solid #ccc',
            resize: 'none'  // Prevents resizing
        }}
    />
</Grid>


            </Grid>
            <Grid container spacing={4} justifyContent="center">
            <Grid item>
        <button
            className="update"
            onClick={handleUpdate}
            disabled={!isUpdateEnabled}
            style={{
                backgroundColor: !isUpdateEnabled ? '#ccc' : '',      // Gray background only when disabled
                cursor: !isUpdateEnabled ? 'not-allowed' : 'pointer', // Pointer when enabled, not-allowed when disabled
                opacity: !isUpdateEnabled ? 0.6 : 1,                  // Fade when disabled, full opacity when enabled
            }}
        >
            <strong>Update</strong>
        </button>
    </Grid>
                <Grid item>
                    <button className="cancel" onClick={onClose}><strong>Cancel</strong></button>
                </Grid>
                <Grid item>
                    <button className="delete" onClick={handleDelete}><strong>Delete</strong></button>
                </Grid>
            </Grid>
        </div>
    );
}


export default RegisteredClientsModal;
