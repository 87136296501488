import React, { useState, useEffect } from 'react';
import { Button, Box, Grid, Typography } from '@mui/material';
import { collection, query, where, getDocs, doc } from 'firebase/firestore'; // Firestore query functions
import SingleDayLimit from './SingleDayLimit'; // Sub-component for single day limits

const TabContentLimitsOnSingleDay = ({ singleDayRules, setSingleDayRules, selectedCalendarToEdit, userEmail, physician, firestore, currentActiveUser }) => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(null); // null means no month is selected
    const [singleDayLimits, setSingleDayLimits] = useState([]);
    const [encounterTypes, setEncounterTypes] = useState ([]);
    const db = firestore;
    
    // console.log("Selected Month:", selectedMonth);
    // console.log("User Email:", userEmail);
    // console.log("Physician:", physician);
    // console.log("Selected Calendar to Edit:", selectedCalendarToEdit);


    useEffect(() => {
        if (selectedMonth === null || !userEmail || !physician || !selectedCalendarToEdit) return;

        const generateDateSequences = (year, month) => {
            let dates = [];
            let lastDay = new Date(year, month + 1, 0).getDate();
            for (let day = 1; day <= lastDay; day++) {
                dates.push(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
            }
            return dates;
        };

        const fetchEncounterTypes = async () => {
            // Fetch encounter types from 'encTypes' collection
            const encTypesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes');
            const encTypesSnapshot = await getDocs(encTypesRef);
            let types = [];
            encTypesSnapshot.forEach((doc) => {
                types.push({ label: doc.id, value: doc.id.toLowerCase(), category: doc.data().category });
            });
        
            // Fetch linked groups from 'encTypeLinks' subcollection
            const linksRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypeLinks');
            const linksSnapshot = await getDocs(linksRef);
            let linkedTypes = new Map();
        
            // Map each type to its group name (if linked)
            linksSnapshot.forEach((linkDoc) => {
                linkDoc.data().linked.forEach((linkedType) => {
                    linkedTypes.set(linkedType, linkDoc.id);
                });
            });
        
            // Update the types array to replace types with their group names where applicable
            types = types.map((type) => {
                if (linkedTypes.has(type.label)) {
                    return { label: linkedTypes.get(type.label), value: linkedTypes.get(type.label).toLowerCase(), category: type.category };
                }
                return type;
            });
        
            // To ensure uniqueness (as multiple types could be linked under one group name), use a Set
            const uniqueTypes = Array.from(new Set(types.map(JSON.stringify))).map(JSON.parse);
        
            // Sort types by label
            uniqueTypes.sort((a, b) => a.label.localeCompare(b.label));
        
            setEncounterTypes(uniqueTypes);
        };
        
        // const fetchEncounterTypes = async () => {
        //     const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendarToEdit, 'encTypes'));
        //     const querySnapshot = await getDocs(q);
        //     let types = [];
        //     querySnapshot.forEach((doc) => {
        //       // Assuming each doc has a name property for the encounter type
        //       types.push({ label: doc.id, value: doc.id.toLowerCase() }); // Adjust this line based on actual document structure
        //     });
        //     types.sort((a, b) => a.label.localeCompare(b.label)); // Sorting alphabetically by label
        //     setEncounterTypes(types);
        // };
        
        
          fetchEncounterTypes();

        const dates = generateDateSequences(currentYear, selectedMonth);
        const promises = dates.map(date => {
            // Assuming 'events' is a known subcollection under each date.
            const dayCollectionRef = collection(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${date}/`);
            return getDocs(dayCollectionRef);
        });

        Promise.all(promises).then(results => {
            const fetchedLimits = [];
            results.forEach((querySnapshot, index) => {
                if (!querySnapshot.empty) {
                    fetchedLimits.push({ date: dates[index], data: querySnapshot.docs.map(doc => doc.data()) });
                }
            });
            setSingleDayLimits(fetchedLimits);
            console.log(fetchedLimits)
        }).catch(error => {
            console.error("Error fetching single day limits:", error);
        });
    }, [currentYear, selectedMonth, userEmail, physician, selectedCalendarToEdit]);

    


    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];


const addSingleDayLimit = () => {
    // Default to the first day of the selected month/year
    const newDate = new Date(currentYear, selectedMonth, 1);

    // Map encounterTypes to the desired structure for a single day limit
    // This example assumes you want to initialize each encounterType with a quantity of 0
    // Adjust the structure as needed for your application
    const initialEncounterTypes = encounterTypes.map(type => ({
        type: type.value, // or type.label, depending on which one you use
        qty: 0 // Initialize quantity (or any other property) as needed
    }));

    setSingleDayLimits([
        ...singleDayLimits,
        { 
            date: newDate.toISOString(),
            encounterTypes: initialEncounterTypes // Use the mapped encounterTypes for the new limit
        }
    ]);
};


    // // Modified addSingleDayLimit function to include the selected month and year
    // const addSingleDayLimit = () => {
    //     // Default to the first day of the selected month/year
    //     const newDate = new Date(currentYear, selectedMonth, 1);
    //     setSingleDayLimits([...singleDayLimits, { date: newDate.toISOString(), encounterTypes: [] }]);
    // };

    const saveSingleDayLimit = (index, singleDayLimitData) => {
        const updatedSingleDayLimits = [...singleDayLimits];
        updatedSingleDayLimits[index] = singleDayLimitData;
        setSingleDayLimits(updatedSingleDayLimits);
    };

    const deleteSingleDayLimit = (index) => {
        const updatedSingleDayLimits = singleDayLimits.filter((_, i) => i !== index);
        setSingleDayLimits(updatedSingleDayLimits);
    };

    const saveSingleDayLimits = () => {
        setSingleDayRules(singleDayLimits);
    };

    console.log(singleDayLimits)

    if (selectedMonth !== null) {
        // Filter singleDayLimits by the selected month and year
        const filteredLimits = singleDayLimits.filter(limit => {
            const [year, month, _] = limit.date.split('-').map(Number); // Split the date string and convert to numbers
            // Adjust the month by 1 because `selectedMonth` is likely to be 1-based (e.g., January = 1)
            return year === currentYear && month === (selectedMonth + 1);
        });
        
    
        // Log the result to the console
        console.log('Filtered Limits:', filteredLimits);
    
        const goBack = () => {
            setSingleDayLimits([])
            setSelectedMonth(null)
        };
        

        return (
            <Box>
                {/* Button to go back to month selection */}
                <Button onClick={goBack}>Back to Month Selection</Button>
                
                {/* Render SingleDayLimits specific for the selected month and year */}
                <div style={{
                    maxHeight: '600px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginBottom: '20px',
                }}>

                    {filteredLimits.map((limit, index) => (
                        <SingleDayLimit
                            key={index}
                            singleDayLimit={limit}
                            onSave={(data) => saveSingleDayLimit(index, data)}
                            onDelete={() => deleteSingleDayLimit(index)}
                            selectedCalendarToEdit={selectedCalendarToEdit}
                            userEmail={userEmail} 
                            physician={physician}
                            firestore={firestore}
                            currentActiveUser={currentActiveUser}
                            encounterTypes={encounterTypes}
                        />
                    ))}

                    {/* {filteredLimits.map((limit, index) => (
                        <SingleDayLimit
                            key={index}
                            singleDayLimit={limit}
                            onSave={(data) => saveSingleDayLimit(index, data)}
                            onDelete={() => deleteSingleDayLimit(index)}
                            selectedCalendarToEdit={selectedCalendarToEdit}
                            userEmail={userEmail} 
                            physician={physician}
                            firestore={firestore}
                            currentActiveUser={currentActiveUser}
                            encounterTypes={encounterTypes}
                        />
                    ))} */}
                </div>
                <Button onClick={addSingleDayLimit}>Add Single Day Limit</Button>
                {/* <Button onClick={saveSingleDayLimits}>Save</Button> */}

<div style={{ color: 'black', marginBottom: '20px', border: '1px solid black', padding: '10px', marginTop: '20px' }}>
  <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
    <li style={{ fontWeight: 'bold' }}>
      IMPORTANT RULES:
    </li>
    <li style={{ paddingLeft: '20px' }}>
      1) The smallest time range possible is 1 whole hour. 1 whole hour is defined as
      00:00 to 00:59 (e.g., if the time range is from 8 AM to 9 AM, it shall be written as
    </li>
    <li style={{ paddingLeft: '40px' }}>
      08:00 AM to 08:59 AM, also 08:30 AM to 09:30 AM will NOT work as is not a compatible whole hour!).
    </li>
    <li style={{ paddingLeft: '20px' }}>
      2) NEVER overlap time ranges! (e.g., a time range 08:00 AM to 08:59 AM, another time range 07:00 AM
      to 10:00 AM).
    </li>
    <li style={{ paddingLeft: '20px' }}>
      3) Time ranges CAN have multiple hours within (e.g., from 8 AM to 5 PM would need to be set
      as 08:00 AM to 04:59 PM).
    </li>
  </ul>
</div>

            </Box>
        );
    }

    // UI for selecting year and month
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <Button onClick={() => setCurrentYear(currentYear - 1)}>{"<"}</Button>
                <Typography variant="h6" sx={{ marginX: '20px' }}>{currentYear}</Typography>
                <Button onClick={() => setCurrentYear(currentYear + 1)}>{">"}</Button>
            </Box>
            <Grid container spacing={2}>
                {months.map((month, index) => (
                    <Grid item xs={3} key={index}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => setSelectedMonth(index)}
                            sx={{ height: '100%' }}
                        >
                            {month}
                        </Button>
                    </Grid>
                ))}
            </Grid>
<div style={{ color: 'black', marginBottom: '20px', border: '1px solid black', padding: '10px', marginTop: '35px' }}>
  <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
    <li style={{ fontWeight: 'bold' }}>
      IMPORTANT RULES:
    </li>
    <li style={{ paddingLeft: '20px' }}>
      1) The smallest time range possible is 1 whole hour. 1 whole hour is defined as
      00:00 to 00:59 (e.g., if the time range is from 8 AM to 9 AM, it shall be written as
    </li>
    <li style={{ paddingLeft: '40px' }}>
      08:00 AM to 08:59 AM, also 08:30 AM to 09:30 AM will NOT work as is not a compatible whole hour!).
    </li>
    <li style={{ paddingLeft: '20px' }}>
      2) NEVER overlap time ranges! (e.g., a time range 08:00 AM to 08:59 AM, another time range 07:00 AM
      to 10:00 AM).
    </li>
    <li style={{ paddingLeft: '20px' }}>
      3) Time ranges CAN have multiple hours within (e.g., from 8 AM to 5 PM would need to be set
      as 08:00 AM to 04:59 PM).
    </li>
  </ul>
</div>
        </Box>
    );
};

export default TabContentLimitsOnSingleDay;