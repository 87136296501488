import React, { useState } from 'react';
import { Modal, Button, Tooltip, IconButton } from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Default styles
import CancelIcon from '@mui/icons-material/Cancel';
import TabContentLimitsByEncounterType from './TabContentLimitsByEncounterType';
import TabContentLimitsOnDateRange from './TabContentOtherSettings';
import TabContentLimitsOnSingleDay from './TabContentLimitsOnSingleDay';
import SettingsIcon from '@mui/icons-material/Settings';
import EncTypeSettingsModal from './EncTypeSettingsModal'; // Import the modal component


const CalendarRulesModal = ({ open, handleClose, userEmail, physician, firestore, currentActiveUser, selectedCalendarToEdit }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [encounterTypeRules, setEncounterTypeRules] = useState({});
    const [dateRangeRules, setDateRangeRules] = useState([]);
    const [singleDayRules, setSingleDayRules] = useState([]);
    const [openEncTypeSettingsModal, setOpenEncTypeSettingsModal] = useState(false);
    

    const db = firestore;

    const handleCloseEncTypeSettingsModal = () => {
        setOpenEncTypeSettingsModal(false);
      };
      
      const handleOpenEncTypeSettingsModal = (event) => {
        event.stopPropagation();
        setOpenEncTypeSettingsModal(true);
      };


    return (
        <>
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div style={{ overflow: 'auto', backgroundColor: 'white', padding: '20px' }}>
                <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TabList>
                            <Tab>Limits by Encounter Type</Tab>
                            <Tab>Limits on a Single Day</Tab>
                            <Tab>Other Settings</Tab>
                        </TabList>
                        <div>
                        <Tooltip title="Manage Encounter Types" arrow>
                            <IconButton onClick={handleOpenEncTypeSettingsModal}>
                            <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                        </div>
                    </div>

                    <TabPanel>
                        <TabContentLimitsByEncounterType
                            encounterTypeRules={encounterTypeRules}
                            setEncounterTypeRules={setEncounterTypeRules}
                            selectedCalendarToEdit={selectedCalendarToEdit}
                            userEmail={userEmail} 
                            physician={physician}
                            firestore={firestore}
                            currentActiveUser={currentActiveUser}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TabContentLimitsOnSingleDay
                            singleDayRules={singleDayRules}
                            setSingleDayRules={setSingleDayRules}
                            selectedCalendarToEdit={selectedCalendarToEdit}
                            userEmail={userEmail} 
                            physician={physician}
                            firestore={firestore}
                            currentActiveUser={currentActiveUser}
                        />
                    </TabPanel>
                    <TabPanel>
                    <div style={{ height: '800px', overflow: 'auto' }}>
                        <TabContentLimitsOnDateRange
                            selectedCalendarToEdit={selectedCalendarToEdit}
                            userEmail={userEmail} 
                            physician={physician}
                            firestore={firestore}
                            currentActiveUser={currentActiveUser}
                        />
                    </div>
                    </TabPanel>
                </Tabs>
            </div>
        </Modal>

     <EncTypeSettingsModal
        open={openEncTypeSettingsModal}
        handleClose={handleCloseEncTypeSettingsModal}
        userEmail={userEmail}
        physician={physician}
        firestore={firestore}
        selectedCalendar={selectedCalendarToEdit}
    />

    </>
    );
    
};



export default CalendarRulesModal;
