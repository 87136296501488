import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, MenuItem, FormControl, Grid, InputLabel, Select, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Typography, Collapse } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment-timezone';
import { doc, setDoc, deleteDoc, getDocs, collection } from "firebase/firestore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { format, set } from 'date-fns';
import BasicProgressOverlay from './components/BasicProgressOverlay'; 



const TimePickerInput = ({ label, selectedTime, onChange }) => {
    const timeInputRef = useRef();

    useEffect(() => {
        timeInputRef.current.value = moment(selectedTime).format("HH:mm");
    }, [selectedTime]);

    const handleTimeChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <Grid item xs={12} md={4}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor="timeInput" style={{ color: '#707070', fontSize: '0.875rem' }}>
                    {label}:
                </label>
                <TextField
                    inputRef={timeInputRef}
                    type="time"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true, style: { display: 'none' } }}
                    id="timeInput"
                    inputProps={{
                        step: 60, // 1 min intervals
                    }}
                    onChange={handleTimeChange}
                />
            </div>
        </Grid>
    );
};

const TimeRange = ({ range, encounterTypesOptions, onUpdate, onRemove, encounterTypes }) => {
  const [regularAppts, setRegularAppts] = useState('');
  const [procedureAppts, setProcedureAppts] = useState('');

  console.log("Encounter Types:", encounterTypes);
  console.log("Range:", range);

  useEffect(() => {
    calculateAppointments();
  }, [range.encounterTypes, encounterTypes]);

  const calculateAppointments = () => {
    let regularSum = 0;
    let procedureSum = 0;

    range.encounterTypes.forEach((encounter) => {
      const matchedType = encounterTypes.find(type => type.value.toLowerCase() === encounter.type.toLowerCase());
      if (matchedType) {
        if (matchedType.category === "Regular Appointment") {
          regularSum += encounter.qty || 0;
        } else if (matchedType.category === "Procedure Appointment") {
          procedureSum += encounter.qty || 0;
        }
      }
    });

    setRegularAppts(regularSum.toString());
    setProcedureAppts(procedureSum.toString());
  };

  const handleEncounterTypeChange = (index, type) => {
    const updatedEncounterTypes = range.encounterTypes.map((item, i) => i === index ? { ...item, type } : item);
    onUpdate({ ...range, encounterTypes: updatedEncounterTypes });
  };

  const handleQuantityChange = (index, qty) => {
    const updatedEncounterTypes = range.encounterTypes.map((item, i) => i === index ? { ...item, qty: Number(qty) || 0 } : item);
    onUpdate({ ...range, encounterTypes: updatedEncounterTypes });
  };

  const addEncounterType = () => {
    onUpdate({ ...range, encounterTypes: [...range.encounterTypes, { type: '', qty: 1 }] });
  };

  const convertTimeStringToDate = (timeString) => {
    if (!timeString) {
      return null;
    } else {
      const [hours, minutes] = timeString.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 4 }}>
          <strong>- TIME RANGE:</strong>
        </Typography>
        <TimePickerInput
          selectedTime={convertTimeStringToDate(range.startTime)}
          onChange={(timeString) => {
            onUpdate({ ...range, startTime: timeString });
          }}
        />
        <TimePickerInput
          selectedTime={convertTimeStringToDate(range.endTime)}
          onChange={(timeString) => {
            onUpdate({ ...range, endTime: timeString });
          }}
        />
        <Button variant="contained" onClick={addEncounterType} sx={{ height: '40px', mt: 0.7 }}>Add Encounter Type</Button>
        <Tooltip title="Delete Time Range">
          <IconButton onClick={onRemove} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
          <Typography variant="body2" sx={{ color: 'black' }}>
            Regular Appts:
          </Typography>
          <TextField
            size="small"
            value={regularAppts}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
          <Typography variant="body2" sx={{ color: 'black' }}>
            Procedure Appts:
          </Typography>
          <TextField
            size="small"
            value={procedureAppts}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      {range.encounterTypes.map((encounter, index) => (
        <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 7 }}>
          <FormControl 
            variant="outlined" 
            fullWidth 
            sx={{ 
              '.MuiFormControl-root': { height: '40px', display: 'flex', alignItems: 'center' },
              '.MuiOutlinedInput-root': { height: '40px', '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: '1px' }},
              '.MuiInputLabel-root': { lineHeight: '1', transform: 'translate(14px, 10px) scale(1)', '&.MuiInputLabel-shrink': { transform: 'translate(14px, -6px) scale(0.75)' }},
              '.MuiSelect-select': { height: '40px', py: '10px', boxSizing: 'border-box' },
            }}
          >
            <InputLabel>Encounter Type</InputLabel>
            <Select
              value={encounter.type || ''}
              onChange={(e) => handleEncounterTypeChange(index, e.target.value)}
              label="Encounter Type"
              renderValue={(selected) => {
                const matchedType = encounterTypes.find(e => e.value.toLowerCase() === selected.toLowerCase());
                const isMatched = Boolean(matchedType);
                const displayValue = matchedType ? matchedType.label : selected;
                return (
                  <span style={{ color: isMatched ? 'black' : 'red' }}>
                    {displayValue}
                  </span>
                );
              }}
            >
              {encounterTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              {encounter.type && !encounterTypes.some(e => e.value.toLowerCase() === encounter.type.toLowerCase()) && (
                <MenuItem value={encounter.type} style={{ color: 'red' }}>
                  {encounter.type}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Qty"
            type="number"
            value={encounter.qty}
            onChange={(e) => handleQuantityChange(index, e.target.value)}
            variant="outlined"
            InputProps={{
              style: { height: '40px' },
            }}
            sx={{ 
              '.MuiInputBase-root': { height: '40px', alignItems: 'center' },
              '.MuiOutlinedInput-input': { padding: '9px 14px' },
              width: '100px'
            }}
          />
          <Tooltip title="Delete">
            <IconButton onClick={() => onUpdate({ ...range, encounterTypes: range.encounterTypes.filter((_, i) => i !== index) })} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
};

// const TimeRange = ({ range, encounterTypesOptions, onUpdate, onRemove, encounterTypes }) => {

//   const [regularAppts, setRegularAppts] = useState('');
//   const [procedureAppts, setProcedureAppts] = useState('');


//   console.log("Encounter Types:", encounterTypes);
//   console.log("Range:", range);

//   const handleEncounterTypeChange = (index, type) => {
//     const updatedEncounterTypes = range.encounterTypes.map((item, i) => i === index ? { ...item, type } : item);
//     onUpdate({ ...range, encounterTypes: updatedEncounterTypes });
//   };

//   const handleQuantityChange = (index, qty) => {
//     const updatedEncounterTypes = range.encounterTypes.map((item, i) => i === index ? { ...item, qty: Number(qty) || 0 } : item);
//     onUpdate({ ...range, encounterTypes: updatedEncounterTypes });
//   };

//   const addEncounterType = () => {
//     onUpdate({ ...range, encounterTypes: [...range.encounterTypes, { type: '', qty: 1 }] });
//   };


//     const convertTimeStringToDate = (timeString) => {
//       if (!timeString) {
//         return null; // or undefined, depending on what you want to return for invalid input
//       } else {
//         const [hours, minutes] = timeString.split(':').map(Number);
//         const date = new Date();
//         date.setHours(hours, minutes, 0, 0);
//         return date;
//       }
//     };

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
//   <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
//     <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 4 }}>
//       <strong>- TIME RANGE:</strong>
//     </Typography>
//     <TimePickerInput
//   selectedTime={convertTimeStringToDate(range.startTime)}
//   onChange={(timeString) => {
//     // Directly use timeString if it's already in "HH:MM" format
//     onUpdate({ ...range, startTime: timeString });
//   }}
// />

// <TimePickerInput
//   selectedTime={convertTimeStringToDate(range.endTime)}
//   onChange={(timeString) => {
//     // Directly use timeString if it's already in "HH:MM" format
//     onUpdate({ ...range, endTime: timeString });
//   }}
// />

//     <Button variant="contained" onClick={addEncounterType} sx={{ height: '40px', mt: 0.7 }}>Add Encounter Type</Button>
//     <Tooltip title="Delete Time Range">
//       <IconButton onClick={onRemove} color="error">
//         <DeleteIcon />
//       </IconButton>
//     </Tooltip>

//     <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
//                     <Typography variant="body2" sx={{ color: 'black' }}>
//                         Regular Appts:
//                     </Typography>
//                     <TextField
//                         size="small"
//                         value={regularAppts}
//                         InputProps={{
//                             readOnly: true,
//                         }}
//                     />
//                 </Box>
//                 <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 2 }}>
//                     <Typography variant="body2" sx={{ color: 'black' }}>
//                         Procedure Appts:
//                     </Typography>
//                     <TextField
//                         size="small"
//                         value={procedureAppts}
//                         InputProps={{
//                             readOnly: true,
//                         }}
//                     />
//                 </Box>

//   </Box>
//   {range.encounterTypes.map((encounter, index) => (
//   <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center', ml: 7 }}>
//     <FormControl 
//       variant="outlined" 
//       fullWidth 
//       sx={{ 
//         '.MuiFormControl-root': { height: '40px', display: 'flex', alignItems: 'center' },
//         '.MuiOutlinedInput-root': { height: '40px', '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: '1px' }},
//         '.MuiInputLabel-root': { lineHeight: '1', transform: 'translate(14px, 10px) scale(1)', '&.MuiInputLabel-shrink': { transform: 'translate(14px, -6px) scale(0.75)' }},
//         '.MuiSelect-select': { height: '40px', py: '10px', boxSizing: 'border-box' }, // Adjust padding to vertically center the text
//       }}
//     >
//       <InputLabel>Encounter Type</InputLabel>
//       <Select
//     value={encounter.type || ''} // Use the encounter type as-is for the value
//     onChange={(e) => handleEncounterTypeChange(index, e.target.value)}
//     label="Encounter Type"
//     renderValue={(selected) => {
//         // Find the original encounter type object to maintain casing
//         const matchedType = encounterTypes.find(e => e.value.toLowerCase() === selected.toLowerCase());
//         // Determine if the selected type is found within the predefined list
//         const isMatched = Boolean(matchedType);
//         // Use the matched object for displaying label or fall back to the selected value
//         const displayValue = matchedType ? matchedType.label : selected;

//         return (
//             <span style={{ color: isMatched ? 'black' : 'red' }}>
//                 {displayValue}
//             </span>
//         );
//     }}
// >
//     {encounterTypes.map((option) => (
//         <MenuItem key={option.value} value={option.value}>
//             {option.label}
//         </MenuItem>
//     ))}
//     {encounter.type && !encounterTypes.some(e => e.value.toLowerCase() === encounter.type.toLowerCase()) && (
//         // Directly add the non-matching encounter type to ensure it's selectable in its original form
//         <MenuItem value={encounter.type} style={{ color: 'red' }}>
//             {encounter.type}
//         </MenuItem>
//     )}
// </Select>




//     </FormControl>
//     <TextField
//       label="Qty"
//       type="number"
//       value={encounter.qty}
//       onChange={(e) => handleQuantityChange(index, e.target.value)}
//       variant="outlined"
//       InputProps={{
//         style: { height: '40px' }, // Adjust the height of the input field
//       }}
//       sx={{ 
//         '.MuiInputBase-root': { height: '40px', alignItems: 'center' },
//         '.MuiOutlinedInput-input': { padding: '9px 14px' }, // Adjust padding to ensure text is aligned properly and field height is reduced
//         width: '100px' // Adjust width if necessary
//       }}
//     />
//     <Tooltip title="Delete">
//       <IconButton onClick={() => onUpdate({ ...range, encounterTypes: range.encounterTypes.filter((_, i) => i !== index) })} color="error">
//         <DeleteIcon />
//       </IconButton>
//     </Tooltip>
//   </Box>
//   ))}
// </Box>

//   );
// };

const SingleDayLimit = ({ singleDayLimit, onSave, onDelete, selectedCalendarToEdit, userEmail, physician, firestore, currentActiveUser, encounterTypes }) => {
    
  console.log('singleDayLimit:', singleDayLimit);
  console.log('singleDayLimit Data:', singleDayLimit.data);
  // console.log('singleDayLimit Name:', singleDayLimit.data.name);
  console.log('onSave:', onSave);
  console.log('onDelete:', onDelete);
  console.log('selectedCalendarToEdit:', selectedCalendarToEdit);
  console.log('userEmail:', userEmail);
  console.log('physician:', physician);
  console.log('firestore:', firestore);
  console.log('currentActiveUser:', currentActiveUser);
  console.log('encounterTypes:', encounterTypes);

  const db = firestore


  // Define the function to create a date object in the local timezone
    function createDateAsLocalTime(dateString) {
        let [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
        let date = new Date();
        date.setFullYear(year, month - 1, day);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    // Initialize date state with the date from singleDayLimit if available, otherwise use current date
    const dateObject = createDateAsLocalTime(singleDayLimit.date);

    // State to manage the selected date and time ranges
    const [date, setDate] = useState(dateObject);

    const [datesWithRanges, setDatesWithRanges] = useState({});

    const [expanded, setExpanded] = useState(false); // Collapsed by default

    const [showDateSection, setShowDateSection] = useState(true);

    const [copyDialogOpen, setCopyDialogOpen] = useState(false);

    const [datePickers, setDatePickers] = useState([new Date()]);

    const [dates, setDates] = useState([new Date()]); // Initially one date picker with today's date

    const [sourceDate, setSourceDate] = useState('');

    const [nameValue, setNameValue] = useState('');

    const [isInProcess, setIsInProcess] = useState(false);

    // const [nameValue, setNameValue] = useState(() => {
    //   const nameObject = singleDayLimit.data.find(item => 'name' in item);
    //   return nameObject ? nameObject.name : '';
    // });

    // useEffect(() => {
    //   console.log(nameValue);
    // }, [nameValue]);


    console.log(singleDayLimit.data)


    const defaultRange = [{
      startTime: '00:00', // Default start time
      endTime: '00:00',   // Default end time
      encounterTypes: [   // Assuming 'encounterTypes' is expected to be an array
        {
          type: '',      // Default type, empty if not known
          qty: 0,        // Default quantity
          // Add more properties as needed per your structure
        }
      ]
      // Include any other properties your component expects
    }];
    

// Use the defaultRange if singleDayLimit.data is empty or undefined
const firstRange = Array.isArray(singleDayLimit.data) && singleDayLimit.data.length > 0 ? singleDayLimit.data[0] : defaultRange;


    // const firstRange = singleDayLimit.data[0]; // Assuming there's at least one range


    const startTime = firstRange ? firstRange.startTime : '';
    const endTime = firstRange ? firstRange.endTime : '';

    // const initialTimeRanges = singleDayLimit.data ? singleDayLimit.data.map(range => ({
    //     startTime: range.startTime,
    //     endTime: range.endTime,
    //     encounterTypes: range.encounterTypes ? range.encounterTypes.map(encounterType => {
    //       const type = Object.keys(encounterType)[0]; // Gets the first key, assuming each object has only one key-value pair
    //       const qty = encounterType[type];
    //       return { type, qty };
    //     }) : []
    //   })) : [];

    const initialTimeRanges = Array.isArray(singleDayLimit.data) && singleDayLimit.data.length > 0 ? singleDayLimit.data.map(range => ({
      startTime: range.startTime,
      endTime: range.endTime,
      encounterTypes: Array.isArray(range.encounterTypes) ? range.encounterTypes.map(encounterType => {
        const type = Object.keys(encounterType)[0]; // Safely assume one key-value pair
        const qty = encounterType[type];
        return { type, qty: parseInt(qty, 10) || 0 }; // Ensure qty is a number
      }) : []
    })) : [{ // Default range structure if `singleDayLimit.data` is undefined or empty
      startTime: '08:00', // Default start time
      endTime: '09:00',   // Default end time
      encounterTypes: [{   // Default encounterTypes with one entry
        type: '',      // Default type, empty if not known
        qty: 0,        // Default quantity
      }]
    }];
    
    
      

      console.log(initialTimeRanges)

    const [timeRanges, setTimeRanges] = useState(initialTimeRanges);

    const [open, setOpen] = useState(false);
  
// // Assuming singleDayLimit.data is an array of time ranges
// const allEncounterTypes = singleDayLimit.data.flatMap(range =>
//   range.encounterTypes.flatMap(encounterType => encounterType.type)
// );

// // Deduplicate the types
// const uniqueEncounterTypes = [...new Set(allEncounterTypes)];

// Ensure singleDayLimit.data is an array before proceeding
const encounterTypesOptions = (Array.isArray(singleDayLimit.data) ? singleDayLimit.data : []).flatMap(range => 
  // Ensure range.encounterTypes is also an array
  (Array.isArray(range.encounterTypes) ? range.encounterTypes : []).flatMap(encounter => 
    Object.keys(encounter).map(key => ({ label: key, value: key }))
  )
);


console.log(encounterTypesOptions)

  

// Function to add a new time range with default times in "HH:MM" format
const addTimeRange = () => {
  setTimeRanges([...timeRanges, { startTime: '08:00', endTime: '09:00', encounterTypes: [{ type: '', qty: 1 }] }]);
};


  
    // Function to update a specific time range
    const updateTimeRange = (index, updatedRange) => {
      const updatedTimeRanges = timeRanges.map((range, i) => i === index ? updatedRange : range);
      setTimeRanges(updatedTimeRanges);
    };
  
    // Function to remove a specific time range
    const removeTimeRange = (indexToRemove) => {
      setTimeRanges(timeRanges.filter((_, index) => index !== indexToRemove));
    };
  
    // Handling dialog open/close
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  



// // Handling delete operation
// const handleDelete = async () => {
//   const formattedDate = moment(date).format('YYYY-MM-DD'); // Format the date

//   const dateRef = collection(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}`);

//   try {
//     // Delete documents in Firestore
//     const snapshot = await getDocs(dateRef);
//     const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
//     await Promise.all(deletePromises);
//     console.log(`All documents in the collection for date: ${formattedDate} have been deleted.`);

//     // Update local state to reflect deletion
//     setTimeRanges([]); // Clears the time ranges for the UI
//   } catch (error) {
//     console.error("Error deleting documents:", error);
//   }

//   handleClose(); // Close the confirmation dialog
// };

// // Handling delete operation
// const handleDelete = async () => {
//   const formattedDate = moment(date).format('YYYY-MM-DD'); // Format the date

//   const dateRef = collection(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}`);

//   try {
//     // Delete documents in Firestore
//     const snapshot = await getDocs(dateRef);
//     const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
//     await Promise.all(deletePromises);
//     console.log(`All documents in the collection for date: ${formattedDate} have been deleted.`);

//     // Update local state to reflect deletion
//     setTimeRanges([]); // Clears the time ranges for the UI

//     // Here you might set the date to null, to the current date, or to another relevant date
//     // For example, setting date to null (make sure your DatePicker and related components handle this gracefully)
//     setDate(null); 

//     // Or set date to a new Date object representing the current day
//     // setDate(new Date());

//   } catch (error) {
//     console.error("Error deleting documents:", error);
//   }

//   handleClose(); // Close the confirmation dialog
// };

const handleDelete = async () => {
  const formattedDate = moment(date).format('YYYY-MM-DD'); // Format the date

  const dateRef = collection(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}`);

  try {
    // Delete documents in Firestore
    const snapshot = await getDocs(dateRef);
    const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    console.log(`All documents in the collection for date: ${formattedDate} have been deleted.`);
    alert(`All documents in the collection for date: ${formattedDate} have been deleted.`);

    // Update local state to reflect deletion
    setTimeRanges([]); // Clears the time ranges for the UI
    setShowDateSection(false); // Hide the date section

  } catch (error) {
    console.error("Error deleting documents:", error);
  }

  handleClose(); // Close the confirmation dialog
};


useEffect(() => {
  if (Array.isArray(singleDayLimit.data)) {
    const nameObject = singleDayLimit.data.find(item => 
      item && typeof item === 'object' && 'name' in item
    );
    setNameValue(nameObject ? nameObject.name : '');
  } else {
    setNameValue('');
  }
}, [singleDayLimit]);

// useEffect(() => {
//   const nameObject = singleDayLimit.data.find(item => 'name' in item);
//   setNameValue(nameObject ? nameObject.name : '');
// }, [singleDayLimit]);

  
    useEffect(() => {
      const initialTimeRanges = Array.isArray(singleDayLimit.data) && singleDayLimit.data.length > 0 ? singleDayLimit.data.map(range => ({
        startTime: range.startTime,
        endTime: range.endTime,
        encounterTypes: Array.isArray(range.encounterTypes) ? range.encounterTypes.map(encounterType => {
          const type = Object.keys(encounterType)[0]; // Safely assume one key-value pair
          const qty = encounterType[type];
          return { type, qty: parseInt(qty, 10) || 0 }; // Ensure qty is a number
        }) : []
      })) : [{ // Default range structure if `singleDayLimit.data` is undefined or empty
        startTime: '08:00', // Default start time
        endTime: '09:00',   // Default end time
        encounterTypes: [{   // Default encounterTypes with one entry
          type: '',      // Default type, empty if not known
          qty: 0,        // Default quantity
        }]
      }];
      
      setTimeRanges(initialTimeRanges);
    }, [singleDayLimit]);
    


    const handleSave = async () => {
      console.log("Saving...");
      const formattedDate = moment(date).format('YYYY-MM-DD');
      console.log(`Formatted Date: ${formattedDate}`);
      console.log(`Name Value: ${nameValue}`);
  
      try {
          // Step 1: Delete existing time range documents for the given date, excluding the "dayName" document
          const dateRef = collection(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}`);
          const snapshot = await getDocs(dateRef);
          const deletePromises = snapshot.docs
              .filter(doc => doc.id !== 'dayName')
              .map((doc) => deleteDoc(doc.ref));
          await Promise.all(deletePromises);
          console.log(`Deleted existing time ranges for date: ${formattedDate}`);
  
          // Step 2: Save each new time range
          for (const range of timeRanges) {
              if (!range.startTime || !range.endTime) {
                  console.error(`Invalid time range found:`, range);
                  continue; // Skip this invalid range and continue with the next one
              }
  
              const docId = `${range.startTime.replace(':', '')}-${range.endTime.replace(':', '')}`;
              console.log(`Range Document ID: ${docId}`);
  
              const docRef = doc(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}/${docId}`);
  
              const encounterTypesArray = range.encounterTypes.map(encounter => {
                // Ensure qty is not NaN, set to 0 if it is
                const qty = isNaN(encounter.qty) ? 0 : encounter.qty;
                
                return { [encounter.type]: qty };
            });
            
              // const encounterTypesArray = range.encounterTypes.map(encounter => {
              //     return { [encounter.type]: encounter.qty };
              // });
  
              // Calculate maxRegularAppts and maxProcedureAppts
              let maxRegularAppts = 0;
              let maxProcedureAppts = 0;

              range.encounterTypes.forEach(encounter => {
                // Ensure qty is not NaN, set to 0 if it is
                const qty = isNaN(encounter.qty) ? 0 : encounter.qty;
            
                const matchedType = encounterTypes.find(et => et.value.toLowerCase() === encounter.type.toLowerCase());
                if (matchedType) {
                    if (matchedType.category === "Regular Appointment") {
                        maxRegularAppts += qty;
                    } else if (matchedType.category === "Procedure Appointment") {
                        maxProcedureAppts += qty;
                    }
                }
            });
            
  
              // range.encounterTypes.forEach(encounter => {
              //     const matchedType = encounterTypes.find(et => et.value.toLowerCase() === encounter.type.toLowerCase());
              //     if (matchedType) {
              //         if (matchedType.category === "Regular Appointment") {
              //             maxRegularAppts += encounter.qty;
              //         } else if (matchedType.category === "Procedure Appointment") {
              //             maxProcedureAppts += encounter.qty;
              //         }
              //     }
              // });
  
              const docData = {
                  startTime: range.startTime,
                  endTime: range.endTime,
                  encounterTypes: encounterTypesArray,
                  maxRegularAppts: maxRegularAppts,
                  maxProcedureAppts: maxProcedureAppts
              };
  
              // Save the document
              await setDoc(docRef, docData);
              console.log(`Document successfully written for date: ${formattedDate} with ID: ${docId}`);
              console.log(`Max Regular Appts: ${maxRegularAppts}, Max Procedure Appts: ${maxProcedureAppts}`);
          }
  
          // Step 3: Save or update the "dayName" document
          const dayNameDocRef = doc(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}/dayName`);
          await setDoc(dayNameDocRef, { name: nameValue }, { merge: true });
          console.log(`DayName document successfully written for date: ${formattedDate} with name: ${nameValue}`);
  
          // Add alert after all operations have been completed successfully
          alert(`All time ranges and day name successfully saved for date: ${formattedDate}`);
      } catch (error) {
          console.error("Error processing document:", error);
          alert(`Error saving time ranges for date: ${formattedDate}. Please try again.`);
      }
  };


    // const handleSave = async () => {
    //   console.log("Saving...");
    //   const formattedDate = moment(date).format('YYYY-MM-DD');
    //   console.log(`Formatted Date: ${formattedDate}`);
    //   console.log(`Name Value: ${nameValue}`);
    
    //   try {
    //     // Step 1: Delete existing time range documents for the given date, excluding the "dayName" document
    //     const dateRef = collection(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}`);
    //     const snapshot = await getDocs(dateRef);
    //     const deletePromises = snapshot.docs
    //       .filter(doc => doc.id !== 'dayName')
    //       .map((doc) => deleteDoc(doc.ref));
    //     await Promise.all(deletePromises);
    //     console.log(`Deleted existing time ranges for date: ${formattedDate}`);
    
    //     // Step 2: Save each new time range
    //     for (const range of timeRanges) {
    //       if (!range.startTime || !range.endTime) {
    //         console.error(`Invalid time range found:`, range);
    //         continue; // Skip this invalid range and continue with the next one
    //       }
    
    //       const docId = `${range.startTime.replace(':', '')}-${range.endTime.replace(':', '')}`;
    //       console.log(`Range Document ID: ${docId}`);
    
    //       const docRef = doc(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}/${docId}`);
    
    //       const encounterTypesArray = range.encounterTypes.map(encounter => {
    //         return { [encounter.type]: encounter.qty };
    //       });
    
    //       const docData = {
    //         startTime: range.startTime,
    //         endTime: range.endTime,
    //         encounterTypes: encounterTypesArray
    //       };
    
    //       // Save the document
    //       await setDoc(docRef, docData);
    //       console.log(`Document successfully written for date: ${formattedDate} with ID: ${docId}`);
    //     }
    
    //     // Step 3: Save or update the "dayName" document
    //     const dayNameDocRef = doc(db, `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${formattedDate}/dayName`);
    //     await setDoc(dayNameDocRef, { name: nameValue }, { merge: true });
    //     console.log(`DayName document successfully written for date: ${formattedDate} with name: ${nameValue}`);
    
    //     // Add alert after all operations have been completed successfully
    //     alert(`All time ranges and day name successfully saved for date: ${formattedDate}`);
    //   } catch (error) {
    //     console.error("Error processing document:", error);
    //     alert(`Error saving time ranges for date: ${formattedDate}. Please try again.`);
    //   }
    // };

    
  
  
  
  
  
  
  

  
//     return (
// <Box sx={{ mt: 2 }}>
//   <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//   <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 1, fontSize: '1.25rem' }}> {/* Adjust font size as needed */}
//   <strong>DAY:</strong>
// </Typography>
// <DatePicker
//   selected={date}
//   onChange={(newDate) => setDate(newDate)}
//   customInput={
//     <TextField
//       fullWidth
//       variant="outlined"
//       sx={{ 
//         mb: 0, // Adjusting bottom margin
//         '.MuiInputBase-input': { height: '6px', fontSize: '1.25rem' }, // Adjust font size here
//         ml: -6
//       }}
//     />
//   }
//   dateFormat="MMMM d, yyyy"
// />
// <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
//   <Tooltip title="Delete Date and All Time Ranges">
//     <IconButton onClick={handleOpen} color="error">
//       <DeleteIcon />
//     </IconButton>
//   </Tooltip>
// </div>

//   </Box>
//         {/* Render time ranges */}
//         {timeRanges.map((range, index) => (
//           <TimeRange
//             key={index}
//             range={range}
//             encounterTypesOptions={encounterTypesOptions || []}
//             onUpdate={(updatedRange) => updateTimeRange(index, updatedRange)}
//             onRemove={() => removeTimeRange(index)}
//             encounterTypes={encounterTypes}
//           />
//         ))}
//         <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
//           <Button variant="contained" onClick={addTimeRange}>
//             Add Time Range
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleSave}>  {/* Corrected */}
//               Save / Update Range(s) To Database For This Day
//           </Button>

//         </Box>
//         {/* Confirmation dialog for deletion */}
//         <Dialog open={open} onClose={handleClose}>
//           <DialogTitle>{"Confirm Delete"}</DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               Are you sure you want to delete this date and all associated time ranges and encounter types?
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>Cancel</Button>
//             <Button onClick={handleDelete} color="error">Delete</Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     );
//   };


// return (
//   <Box sx={{ mt: 2 }}>
//     <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//       <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 1, fontSize: '1.25rem' }}>
//         <strong>DAY:</strong>
//       </Typography>
//       <DatePicker
//         selected={date}
//         onChange={(newDate) => setDate(newDate)}
//         customInput={
//           <TextField
//             fullWidth
//             variant="outlined"
//             sx={{ 
//               mb: 0,
//               '.MuiInputBase-input': { height: '6px', fontSize: '1.25rem' },
//               ml: -6
//             }}
//           />
//         }
//         dateFormat="MMMM d, yyyy"
//       />
//       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
//         <Tooltip title="Delete Date and All Time Ranges">
//           <IconButton onClick={handleOpen} color="error">
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       </div>
//       <IconButton onClick={() => setExpanded(!expanded)}>
//         {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//       </IconButton>
//     </Box>

//     <Collapse in={expanded}>
//       {timeRanges.map((range, index) => (
//         <TimeRange
//           key={index}
//           range={range}
//           encounterTypesOptions={encounterTypesOptions || []}
//           onUpdate={(updatedRange) => updateTimeRange(index, updatedRange)}
//           onRemove={() => removeTimeRange(index)}
//           encounterTypes={encounterTypes}
//         />
//       ))}
    

//     <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
//       <Button variant="contained" onClick={addTimeRange}>
//         Add Time Range
//       </Button>
//       <Button variant="contained" color="primary" onClick={handleSave}>
//         Save / Update Range(s) To Database For This Day
//       </Button>
//     </Box>

//     </Collapse>

//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>{"Confirm Delete"}</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Are you sure you want to delete this date and all associated time ranges and encounter types?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>Cancel</Button>
//         <Button onClick={handleDelete} color="error">Delete</Button>
//       </DialogActions>
//     </Dialog>
//   </Box>
// );
// };


const handleCopyOpen = () => {
  const formattedDate = format(date, 'yyyy-MM-dd'); // Ensuring the date is formatted correctly
  console.log(formattedDate)
  setSourceDate(formattedDate); // Storing the formatted date for later use
  setCopyDialogOpen(true); // Opening the dialog
};

const handleAddDatePicker = () => {
  // Assuming `datePickers` holds Date objects. You can set a new Date or any default date.
  const newDate = new Date(); // This will add the current date. Modify if you need a different default date.
  const newDates = [...datePickers, newDate]; // Creates a new array with the old dates and the new date.
  setDatePickers(newDates); // Updates the state with the new array.
};


const handleRemoveDatePicker = (index) => {
  const newDates = [...datePickers];
  newDates.splice(index, 1);
  setDatePickers(newDates);
};

const handleDateChange = (newDate, index) => {
  console.log("Before Update - Current dates:", datePickers.map(d => d.toISOString()));  // Ensure dates are logged in readable format
  const newDates = [...datePickers];
  newDates[index] = newDate;
  setDatePickers(newDates);
  console.log("After Update - New dates:", newDates.map(d => d.toISOString()));  // Debugging the updated array
};

// const handleDateChange = (newDate, index) => {
//   console.log("Current dates:", datePickers);
//   console.log(`Updating index ${index} with date`, newDate);

//   const newDates = [...datePickers];
//   newDates[index] = newDate;
//   setDatePickers(newDates);

//   console.log("Updated dates:", newDates);
// };





const handleCopyToSelectedDays = async () => {
  setIsInProcess(true);
  try {
      // Source date is now directly used from the state
      const sourceCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${sourceDate}`;
      console.log(`Source collection path: ${sourceCollectionPath}`);
      const sourceCollectionRef = collection(firestore, sourceCollectionPath);

      // Fetching data from source collection
      const querySnapshot = await getDocs(sourceCollectionRef);
      if (querySnapshot.empty) {
          console.log(`No documents found in '${sourceDate}'. Nothing to copy.`);
          return;
      }

      // Copy to all selected target dates
      for (const targetDate of datePickers) { // assuming `datePickers` are formatted as YYYY-MM-DD
          const targetCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${format(targetDate, 'yyyy-MM-dd')}`;
          console.log(`Target collection path for '${format(targetDate, 'yyyy-MM-dd')}': ${targetCollectionPath}`);
          const targetCollectionRef = collection(firestore, targetCollectionPath);

          // Deleting existing documents in the target collection
          const targetQuerySnapshot = await getDocs(targetCollectionRef);
          for (const docSnapshot of targetQuerySnapshot.docs) {
              try {
                  await deleteDoc(docSnapshot.ref);
                  console.log(`Deleted document ID '${docSnapshot.id}' from '${format(targetDate, 'yyyy-MM-dd')}'...`);
              } catch (error) {
                  console.error(`Error deleting document ID '${docSnapshot.id}' from '${format(targetDate, 'yyyy-MM-dd')}':`, error);
              }
          }

          // Copying documents from source collection to target collection
          for (const docSnapshot of querySnapshot.docs) {
              const targetDocRef = doc(targetCollectionRef, docSnapshot.id);
              console.log(`Copying document ID '${docSnapshot.id}' to '${format(targetDate, 'yyyy-MM-dd')}'...`);
              try {
                  await setDoc(targetDocRef, docSnapshot.data(), { merge: false });
                  console.log(`Document ID '${docSnapshot.id}' successfully copied to '${format(targetDate, 'yyyy-MM-dd')}'.`);
              } catch (error) {
                  console.error(`Error copying document ID '${docSnapshot.id}' to '${format(targetDate, 'yyyy-MM-dd')}':`, error);
              }
          }
      }
      console.log(`Documents successfully copied from '${sourceDate}' to the selected dates.`);
      alert(`Documents successfully copied from '${sourceDate}' to the selected dates.`);
      setIsInProcess(false);
      setCopyDialogOpen(false);
  } catch (error) {
      console.error("Error copying data:", error);
      alert("Error copying data:", error);
      setIsInProcess(false);
  }
};





// const handleCopyToSelectedDays = async () => {
//   try {
//       // Source date is now directly used from the state
//       const sourceCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${sourceDate}`;
//       console.log(`Source collection path: ${sourceCollectionPath}`);
//       const sourceCollectionRef = collection(firestore, sourceCollectionPath);

//       // Fetching data from source collection
//       const querySnapshot = await getDocs(sourceCollectionRef);
//       if (querySnapshot.empty) {
//           console.log(`No documents found in '${sourceDate}'. Nothing to copy.`);
//           return;
//       }

//       // Copy to all selected target dates
//       for (const targetDate of datePickers) { // assuming `dates` are formatted as YYYY-MM-DD
//           const targetCollectionPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendarToEdit}/rules/byDay/${format(targetDate, 'yyyy-MM-dd')}`;
//           console.log(`Target collection path for '${format(targetDate, 'yyyy-MM-dd')}': ${targetCollectionPath}`);
//           const targetCollectionRef = collection(firestore, targetCollectionPath);

//           querySnapshot.forEach(async (docSnapshot) => {
//               const targetDocRef = doc(targetCollectionRef, docSnapshot.id);
//               console.log(`Copying document ID '${docSnapshot.id}' to '${format(targetDate, 'yyyy-MM-dd')}'...`);
//               try {
//                   await setDoc(targetDocRef, docSnapshot.data(), { merge: false });
//                   console.log(`Document ID '${docSnapshot.id}' successfully copied to '${format(targetDate, 'yyyy-MM-dd')}'.`);
//               } catch (error) {
//                   console.error(`Error copying document ID '${docSnapshot.id}' to '${format(targetDate, 'yyyy-MM-dd')}':`, error);
//               }
//           });
//       }
//       console.log(`Documents successfully copied from '${sourceDate}' to the selected dates.`);
//       alert(`Documents successfully copied from '${sourceDate}' to the selected dates.`);
//       setCopyDialogOpen(false);
//   } catch (error) {
//       console.error("Error copying data:", error);
//       alert("Error copying data:", error)
//   }
// };



return (
  <Box sx={{ mt: 2 }}>
    {showDateSection && (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="subtitle1" sx={{ minWidth: '100px', ml: 1, fontSize: '1.25rem' }}>
          <strong>DAY:</strong>
        </Typography>
        <DatePicker
          selected={date}
          onChange={(newDate) => setDate(newDate)}
          customInput={
            <TextField
              fullWidth
              variant="outlined"
              sx={{ 
                mb: 0,
                '.MuiInputBase-input': { height: '6px', fontSize: '1.25rem' },
                ml: -6
              }}
            />
          }
          dateFormat="MMMM d, yyyy"
        />


<TextField
  variant="outlined"
  value={nameValue}
  onChange={(e) => setNameValue(e.target.value)}
  placeholder="Enter day label..."
  sx={{ 
    mb: 0,
    width: 'auto',
    minWidth: 'calc(13ch + 32px)', // Adjust based on placeholder length
    '.MuiInputBase-input': { 
      height: '6px', 
      fontSize: '1.25rem',
      padding: '13px 14px', // Adjust padding as needed
    }
  }}
/>

{/* <TextField
  variant="outlined"
  value={nameValue}
  onChange={(e) => setNameValue(e.target.value)}
  placeholder="Enter day label..."
  sx={{ 
    mb: 0,
    width: 'auto',
    minWidth: 'calc(13ch + 32px)', 
    '.MuiInputBase-input': { 
      height: '6px', 
      fontSize: '1.25rem',
      padding: '13px 14px', 
    }
  }}
/> */}

                <Button 
                    variant="contained" 
                    style={{ backgroundColor: '#1976d2', color: 'white' }}
                    onClick={handleCopyOpen}
                >
                    {`Copy Day To...`}
                </Button>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Tooltip title="Delete Date and All Time Ranges">
            <IconButton onClick={handleOpen} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
    )}

    <Collapse in={expanded && showDateSection}>
      {timeRanges.map((range, index) => (
        <TimeRange
          key={index}
          range={range}
          encounterTypesOptions={encounterTypesOptions || []}
          onUpdate={(updatedRange) => updateTimeRange(index, updatedRange)}
          onRemove={() => removeTimeRange(index)}
          encounterTypes={encounterTypes}
        />
      ))}
      <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
        <Button variant="contained" onClick={addTimeRange}>
          Add Time Range
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save / Update Range(s) To Database For This Day
        </Button>
      </Box>
    </Collapse>

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this date and all associated time ranges and encounter types?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">Delete</Button>
      </DialogActions>
    </Dialog>

    <BasicProgressOverlay
    isInProcess={isInProcess} 
  />

    <Dialog open={copyDialogOpen} onClose={() => setCopyDialogOpen(false)}   PaperProps={{
    style: { width: '80%', height: '40%' }  // Adjust width and maxHeight as needed
  }}>
            <DialogTitle>{"Copy to Other Day(s)"}</DialogTitle>
            <DialogContent>
            {datePickers.map((date, index) => (
  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
    <DatePicker
      selected={date}
      onChange={(newDate) => handleDateChange(newDate, index)}
      dateFormat="MMMM d, yyyy"
    />
    {datePickers.length > 1 && (
      <Button
        onClick={() => handleRemoveDatePicker(index)}
        variant="contained"
        color="secondary"
        style={{ marginLeft: 10 }}
      >
        -
      </Button>
    )}
  </div>
))}

                <Button onClick={handleAddDatePicker} variant="contained" color="primary" style={{ marginTop: 10 }}>
                    +
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setCopyDialogOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button
    onClick={handleCopyToSelectedDays}
    color="primary"
    variant="contained"
>
    Copy
</Button>

            </DialogActions>
        </Dialog>

  </Box>
);
};

  
  export default SingleDayLimit;