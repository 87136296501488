import React from 'react';
import ReactDOM from 'react-dom';
import { Modal as MuiModal } from '@mui/material';
import Box from '@mui/material/Box';

const customStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '500px',
    textAlign: 'center',
};

const buttonStyle = {
    margin: '20px',
    padding: '10px 20px',
    color: '#fff',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
};

const okButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#007bff',
};

const overrideButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#800080',
};

const ModalContent = ({ onRequestClose, onOverride }) => (
    <Box sx={customStyles}>
        <h2>This appointment date/time slot is not available. Please select another!</h2>
        <div>
            <button style={okButtonStyle} onClick={(event) => {
                event.stopPropagation();
                onRequestClose();
            }}>OK</button>
            <button style={overrideButtonStyle} onClick={(event) => {
                event.stopPropagation();
                onOverride(event);
            }}>OVERRIDE</button>
        </div>
    </Box>
);

const NoSpotModal = ({ isOpen, onRequestClose, onOverride }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <MuiModal 
            open={isOpen} 
            onClose={onRequestClose}
            style={{ zIndex: 9998 }} // Ensure this is higher than BasicProgressOverlay
        >
            <ModalContent onRequestClose={onRequestClose} onOverride={onOverride} />
        </MuiModal>,
        document.body
    );
};

export default NoSpotModal;

// import React from 'react';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';

// // Additional CSS for buttons and modal content
// const customStyles = {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     border: '1px solid #ccc', // Optional: Adds a border to the modal
//     background: '#fff',
//     borderRadius: '10px', // Rounded corners for the modal
//     padding: '20px', // Inner padding within the modal
//     maxWidth: '500px', // Restricts the modal's width for better formatting
//     textAlign: 'center', // Aligns text and buttons to the center
// };

// const buttonStyle = {
//     margin: '20px',
//     padding: '10px 20px',
//     color: '#fff',
//     fontWeight: 'bold',
//     border: 'none',
//     borderRadius: '5px', // Rounded corners for buttons
//     cursor: 'pointer'
// };

// const okButtonStyle = {
//     ...buttonStyle,
//     backgroundColor: '#007bff', // Blue color for the OK button
// };

// const overrideButtonStyle = {
//     ...buttonStyle,
//     backgroundColor: '#800080', // Purple color for the Override button
// };

// const NoSpotModal = ({ isOpen, onRequestClose, onOverride }) => {
//     return (
//         <Modal open={isOpen} onClose={onRequestClose}>
//             <Box
//                 sx={{
//                     ...customStyles,
//                     position: 'relative', // Create new stacking context
//                     zIndex: (theme) => theme.zIndex.modal + 1, // Make sure the modal is above the overlay                    // bgcolor: 'rgba(0, 0, 0, 0.75)' // Optional: Adds a slightly darkened backdrop
//                 }}
//             >
//                 <h2>This appointment date/time slot is not available. Please select another!</h2>
//                 <div>
//                 <button style={okButtonStyle} onClick={(event) => {
//                     event.stopPropagation();
//                     onRequestClose();
//                     }}>OK</button>
//                     <button style={overrideButtonStyle} onClick={(event) => {
//                         event.stopPropagation();
//                         onOverride(event);  // Pass the event object explicitly here
//                     }}>OVERRIDE</button>
//                 </div>
//             </Box>
//         </Modal>
//     );
// };

// export default NoSpotModal;