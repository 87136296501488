import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, FormControl, InputLabel, Select, MenuItem, TextField, Tooltip, Button, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { collection, query, getDocs, doc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { set } from 'date-fns';

function EncTypeSettingsModal({ open, handleClose, userEmail, physician, firestore, selectedCalendar }) {
    const nameEncRef = useRef('');
    // const categoryRef = useRef('');

    const [selectedEncounterType, setSelectedEncounterType] = useState('');
    const [encounterTypes, setEncounterTypes] = useState([]);
    const [colorEncType, setColorEncType] = useState('#FFFFFF'); // Initialize with a default color
    const [category, setCategory] = useState('');

    useEffect(() => {
        if (open) {
            fetchEncounterTypes();
        }
    }, [open, userEmail, physician, selectedCalendar, firestore]);

    const fetchEncounterTypes = async () => {
        if (!userEmail || !physician || !selectedCalendar) {
            console.error('Missing required data for Firestore query');
            return;
        }
        const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
        const querySnapshot = await getDocs(q);
        const types = querySnapshot.docs.map(doc => doc.data());
        types.sort((a, b) => a.name.localeCompare(b.name));
        setEncounterTypes(types);
    };

    const unloadSelectedEncTypeItem = () => {
        setSelectedEncounterType('');
        nameEncRef.current = '';
        setColorEncType('#FFFFFF');
        setCategory('');
    };

    const handleSaveOrUpdateEncounterType = async () => {
        if (!nameEncRef.current.trim()) {
            alert('The name field is empty. Please enter a name.');
            return;
        }

        const encounterData = {
            name: nameEncRef.current,
            category: category,
            color: colorEncType
        };

        if (selectedEncounterType) {
            const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
            await updateDoc(docRef, encounterData);
            alert(`Encounter type ( ${nameEncRef.current} ) updated successfully!`);
        } else {
            const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
            const querySnapshot = await getDocs(q);
            const doesNameExist = querySnapshot.docs.some(doc => doc.data().name.toLowerCase() === nameEncRef.current.toLowerCase());

            if (!doesNameExist) {
                const docRef = doc(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'), nameEncRef.current);
                await setDoc(docRef, encounterData);
                alert(`Encounter type ( ${nameEncRef.current} ) saved successfully!`);
                nameEncRef.current = '';
                setCategory('');
                setColorEncType('#FFFFFF');
                fetchEncounterTypes();
                setSelectedEncounterType('');
            } else {
                alert('An encounter type with the same name already exists.');
            }
        }
    };

    const handleDeleteEncounterType = async () => {
        if (selectedEncounterType) {
            try {
                const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
                await deleteDoc(docRef);
                alert(`Encounter type ( ${nameEncRef.current} ) deleted successfully!`);
                fetchEncounterTypes();
                setSelectedEncounterType('');
                nameEncRef.current = '';
                setColorEncType('#FFFFFF');
                setCategory('');
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        } else {
            alert('No encounter type selected for deletion.');
        }
    };

    return (
        <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ position: 'relative', width: 500, padding: 4, background: 'white', borderRadius: 2 }}>
                <h2>Manage Encounter Types</h2>
                <form>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel id="existing-types-label">Existing Types</InputLabel>
                        <Box display="flex" alignItems="center">
                            <Select
                                value={selectedEncounterType || ''}
                                onChange={(e) => {
                                    const selectedType = encounterTypes.find(type => type.name === e.target.value);
                                    setSelectedEncounterType(e.target.value);
                                    nameEncRef.current = selectedType.name;
                                    setColorEncType(selectedType.color);
                                    setCategory(selectedType.category);
                                }}
                                labelId="existing-types-label"
                                label="Existing Types"
                                style={{ flex: 1 }}
                            >
                                {encounterTypes.map((type, index) => (
                                    <MenuItem key={index} value={type.name}>{type.name}</MenuItem>
                                ))}
                            </Select>
                            <Tooltip title="Clear">
                                <RemoveCircleOutlineIcon
                                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                                    onClick={unloadSelectedEncTypeItem}
                                />
                            </Tooltip>
                        </Box>
                    </FormControl>

                    <TextField
                        label="Type Name"
                        fullWidth required
                        margin="normal"
                        variant="outlined"
                        defaultValue={nameEncRef.current}
                        onChange={(e) => nameEncRef.current = e.target.value}
                        disabled={selectedEncounterType ? true : false}
                    />

                    <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
                        <Typography variant="body1" style={{ marginRight: '10px' }}>
                            Color:
                        </Typography>
                        <input
                            type="color"
                            value={colorEncType}
                            onChange={(e) => setColorEncType(e.target.value)}
                            style={{ width: 250, height: 40, marginRight: '10px' }}
                        />
                    </Box>

                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            labelId="category-label"
                            label="Category"
                        >
                            <MenuItem value="Regular Appointment">Regular Appointment</MenuItem>
                            <MenuItem value="Procedure Appointment">Procedure Appointment</MenuItem>
                        </Select>
                    </FormControl>

                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                        <Button variant="contained" color="primary" style={{ width: '120px', marginRight: '20px' }} onClick={handleSaveOrUpdateEncounterType}>
                            {selectedEncounterType ? 'Update' : 'Save'}
                        </Button>
                        <Button variant="contained" style={{ width: '120px', backgroundColor: 'red', marginRight: '20px' }} onClick={handleDeleteEncounterType}>
                            Delete
                        </Button>
                        <Button 
                            variant="contained" 
                            style={{ width: '120px', backgroundColor: 'black' }} 
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default EncTypeSettingsModal;


// import React, { useState, useEffect, useRef } from 'react';
// import { Modal, Box, FormControl, InputLabel, Select, MenuItem, TextField, Tooltip, Button, Typography } from '@mui/material';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import { 
//     collection, 
//     query, 
//     getDocs, 
//     doc, 
//     setDoc, 
//     deleteDoc, 
//     updateDoc 
// } from 'firebase/firestore';

// function EncTypeSettingsModal({ open, handleClose, userEmail, physician, firestore, selectedCalendar }) {
//     const nameEncRef = useRef('');
//     const colorEncTypeRef = useRef('#FFFFFF'); // Initialize with a default color
//     const categoryRef = useRef('');

//     const [selectedEncounterType, setSelectedEncounterType] = useState('');
//     const [encounterTypes, setEncounterTypes] = useState([]);

//     useEffect(() => {
//         if (open) {
//             fetchEncounterTypes();
//         }
//     }, [open, userEmail, physician, selectedCalendar, firestore]);

//     const fetchEncounterTypes = async () => {
//         if (!userEmail || !physician || !selectedCalendar) {
//             console.error('Missing required data for Firestore query');
//             return;
//         }
//         const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
//         const querySnapshot = await getDocs(q);
//         const types = querySnapshot.docs.map(doc => doc.data());
//         types.sort((a, b) => a.name.localeCompare(b.name));
//         setEncounterTypes(types);
//     };

//     const unloadSelectedEncTypeItem = () => {
//         setSelectedEncounterType('');
//         nameEncRef.current = '';
//         colorEncTypeRef.current = '#FFFFFF';
//         categoryRef.current = '';
//     };

//     const handleSaveOrUpdateEncounterType = async () => {
//         if (!nameEncRef.current.trim()) {
//             alert('The name field is empty. Please enter a name.');
//             return;
//         }

//         const encounterData = {
//             name: nameEncRef.current,
//             category: categoryRef.current,
//             color: colorEncTypeRef.current
//         };

//         if (selectedEncounterType) {
//             const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
//             await updateDoc(docRef, encounterData);
//             alert(`Encounter type ( ${nameEncRef.current} ) updated successfully!`);
//         } else {
//             const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
//             const querySnapshot = await getDocs(q);
//             const doesNameExist = querySnapshot.docs.some(doc => doc.data().name.toLowerCase() === nameEncRef.current.toLowerCase());

//             if (!doesNameExist) {
//                 const docRef = doc(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'), nameEncRef.current);
//                 await setDoc(docRef, encounterData);
//                 alert(`Encounter type ( ${nameEncRef.current} ) saved successfully!`);
//                 nameEncRef.current = '';
//                 categoryRef.current = '';
//                 colorEncTypeRef.current = '#FFFFFF';
//                 fetchEncounterTypes();
//                 setSelectedEncounterType('');
//             } else {
//                 alert('An encounter type with the same name already exists.');
//             }
//         }
//     };

//     const handleDeleteEncounterType = async () => {
//         if (selectedEncounterType) {
//             try {
//                 const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
//                 await deleteDoc(docRef);
//                 alert(`Encounter type ( ${nameEncRef.current} ) deleted successfully!`);
//                 fetchEncounterTypes();
//                 setSelectedEncounterType('');
//                 nameEncRef.current = '';
//                 colorEncTypeRef.current = '#FFFFFF';
//                 categoryRef.current = '';
//             } catch (error) {
//                 console.error("Error deleting document: ", error);
//             }
//         } else {
//             alert('No encounter type selected for deletion.');
//         }
//     };

//     return (
//         <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//             <Box sx={{ position: 'relative', width: 500, padding: 4, background: 'white', borderRadius: 2 }}>
//                 <h2>Manage Encounter Types</h2>
//                 <form>
//                     <FormControl fullWidth margin="normal" variant="outlined">
//                         <InputLabel id="existing-types-label">Existing Types</InputLabel>
//                         <Box display="flex" alignItems="center">
//                             <Select
//                                 value={selectedEncounterType || ''}
//                                 onChange={(e) => {
//                                     const selectedType = encounterTypes.find(type => type.name === e.target.value);
//                                     setSelectedEncounterType(e.target.value);
//                                     nameEncRef.current = selectedType.name;
//                                     colorEncTypeRef.current = selectedType.color;
//                                     categoryRef.current = selectedType.category;
//                                 }}
//                                 labelId="existing-types-label"
//                                 label="Existing Types"
//                                 style={{ flex: 1 }}
//                             >
//                                 {encounterTypes.map((type, index) => (
//                                     <MenuItem key={index} value={type.name}>{type.name}</MenuItem>
//                                 ))}
//                             </Select>
//                             <Tooltip title="Clear">
//                                 <RemoveCircleOutlineIcon
//                                     style={{ cursor: 'pointer', marginLeft: '10px' }}
//                                     onClick={unloadSelectedEncTypeItem}
//                                 />
//                             </Tooltip>
//                         </Box>
//                     </FormControl>

//                     <TextField
//                         label="Type Name"
//                         fullWidth required
//                         margin="normal"
//                         variant="outlined"
//                         defaultValue={nameEncRef.current}
//                         onChange={(e) => nameEncRef.current = e.target.value}
//                         disabled={selectedEncounterType ? true : false}
//                     />

//                     <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
//                         <Typography variant="body1" style={{ marginRight: '10px' }}>
//                             Color:
//                         </Typography>
//                         <input
//                             type="color"
//                             value={colorEncTypeRef.current || '#FFFFFF'}
//                             onChange={(e) => colorEncTypeRef.current = e.target.value}
//                             style={{ width: 250, height: 40, marginRight: '10px' }}
//                         />
//                     </Box>

//                     <FormControl fullWidth margin="normal" variant="outlined">
//                         <InputLabel id="category-label">Category</InputLabel>
//                         <Select
//                             value={categoryRef.current || ''}
//                             onChange={(e) => categoryRef.current = e.target.value}
//                             labelId="category-label"
//                             label="Category"
//                         >
//                             <MenuItem value="Regular Appointment">Regular Appointment</MenuItem>
//                             <MenuItem value="Procedure Appointment">Procedure Appointment</MenuItem>
//                         </Select>
//                     </FormControl>

//                     <Box display="flex" justifyContent="space-between" marginTop={2}>
//                         <Button variant="contained" color="primary" style={{ width: '120px', marginRight: '20px' }} onClick={handleSaveOrUpdateEncounterType}>
//                             {selectedEncounterType ? 'Update' : 'Save'}
//                         </Button>
//                         <Button variant="contained" style={{ width: '120px', backgroundColor: 'red', marginRight: '20px' }} onClick={handleDeleteEncounterType}>
//                             Delete
//                         </Button>
//                         <Button 
//                             variant="contained" 
//                             style={{ width: '120px', backgroundColor: 'black' }} 
//                             onClick={handleClose}
//                         >
//                             Cancel
//                         </Button>
//                     </Box>
//                 </form>
//             </Box>
//         </Modal>
//     );
// }

// export default EncTypeSettingsModal;


// // import React, { useState, useEffect } from 'react';
// // import { Modal, Box, FormControl, InputLabel, Select, MenuItem, TextField, Tooltip, Button, Typography } from '@mui/material';
// // import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// // import { 
// //     collection, 
// //     query, 
// //     where, 
// //     getDocs, 
// //     doc, 
// //     setDoc, 
// //     deleteDoc, 
// //     addDoc, 
// //     updateDoc, 
// //     getDoc, 
// //     deleteField, 
// //     startAfter, 
// //     orderBy, 
// //     documentId, 
// //     limit,
// //     Timestamp // Adding Timestamp if you need to work with Firestore timestamps
// //   } from 'firebase/firestore';

// // function EncTypeSettingsModal({ open, handleClose, userEmail, physician, firestore, selectedCalendar }) {
// //     // References setup, these will be managed inside this component now
// //     const nameEncRef = React.useRef('');
// //     const colorEncTypeRef = React.useRef('');
// //     const categoryRef = React.useRef('');

// //     const [selectedEncounterType, setSelectedEncounterType] = React.useState('');
// //     const [savedNewEncounterType, setSavedNewEncounterType] = React.useState(false);
// //     const [encounterTypes, setEncounterTypes] = useState([]);
// //     const [newEncounterType, setNewEncounterType] = useState({ name: '', category: '' });

    


// //     useEffect(() => {
// //         const fetchEncounterTypes = async () => {
// //           // Check if all necessary variables are non-empty
// //           if (!userEmail || !physician || !selectedCalendar) {
// //             console.error('Missing required data for Firestore query');
// //             return; // Exit the function if any variable is empty
// //           }
      
// //           const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
// //           const querySnapshot = await getDocs(q);
// //           let types = [];
// //           querySnapshot.forEach((doc) => {
// //             types.push(doc.data());
// //           });
// //           types.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
// //           setEncounterTypes(types);
// //         };
      
// //         fetchEncounterTypes();
// //       }, [open]); // Added dependencies

// //       const fetchEncounterTypes = async () => {
// //         // Check if all necessary variables are non-empty
// //         if (!userEmail || !physician || !selectedCalendar) {
// //           console.error('Missing required data for Firestore query');
// //           return; // Exit the function if any variable is empty
// //         }
    
// //         const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
// //         const querySnapshot = await getDocs(q);
// //         let types = [];
// //         querySnapshot.forEach((doc) => {
// //           types.push(doc.data());
// //         });
// //         types.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
// //         setEncounterTypes(types);
// //       };

// //     const unloadSelectedEncTypeItem = () => {
// //         // Resetting the selected encounter type
// //         setSelectedEncounterType(null);
      
// //         // Resetting other related states or references
// //         nameEncRef.current = '';
// //         colorEncTypeRef.current = '#FFFFFF'; // Default color
// //         categoryRef.current = ''; // Reset category or set to default
// //         // ... any other resets if needed
// //       };

// //       const handleSaveOrUpdateEncounterType = async () => {
// //         // console.log("Starting encType update!")
// //         // Check if the name field is not empty
// //         if (!nameEncRef.current) {
// //             alert('The name field is empty. Please enter a name.');
// //             return;
// //         }
    
// //         const encounterData = {
// //             name: nameEncRef.current,
// //             category: categoryRef.current,
// //             color: colorEncTypeRef.current // Adding the color to the encounter data
// //         };
    
// //         setSavedNewEncounterType(false);
    
// //         // console.log("Encounter type data with color created, data: " + encounterData)
    
// //         if (selectedEncounterType) {
// //             // Update logic
// //             const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
// //             await updateDoc(docRef, encounterData);
// //             document.getElementById('cancel-button').textContent = 'Close';
// //             document.getElementById('ecnType-update-button').textContent = 'Save';
// //             alert(`Encounter type ( ${nameEncRef.current} ) updated succesfully!`);
// //         } else {
// //             // Save logic with checking duplicate names
// //             const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
// //             const querySnapshot = await getDocs(q);
    
// //             const doesNameExist = querySnapshot.docs.some(doc => 
// //                 doc.data().name.toLowerCase() === nameEncRef.current.toLowerCase()
// //             );
    
// //             if (!doesNameExist) {
// //                 const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', nameEncRef.current);
// //                 await setDoc(docRef, encounterData);
// //                 // setSavedNewEncounterType(false);
// //                 alert(`Encounter type ( ${nameEncRef.current} ) saved succesfully!`);
// //                 nameEncRef.current = ''; // resetting the ref value
// //                 categoryRef.current = ''; // resetting the ref value
// //                 colorEncTypeRef.current = '#ffffff'; // resetting the color ref value
    
// //                 fetchEncounterTypes();
    
// //                 // Clear the selected encounter type and reset the input fields
// //                 setSelectedEncounterType('');
// //                 setNewEncounterType({ name: '', category: '', color: '#ffffff' });
// //                 setSavedNewEncounterType(true);
    
// //                 // document.getElementById('cancel-button').textContent = 'Close';
// //             } else {
// //                 alert('An encounter type with the same name already exists.');
// //             }
// //         }
// //     };

// //     const handleDeleteEncounterType = async () => {
// //         if (selectedEncounterType) {
// //             try {
// //                 // Get a reference to the document to delete
// //                 const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
                
// //                 // Delete the document
// //                 await deleteDoc(docRef);
                
// //                 alert(`Encounter type ( ${nameEncRef.current} ) deleted succesfully!`);

// //                 fetchEncounterTypes()

// //                 // Clear the selected encounter type and reset the input fields
// //                 setSelectedEncounterType('');
// //                 setNewEncounterType({ name: '', category: '', color: '#ffffff' });
// //                 nameEncRef.current = ''; // resetting the ref value
// //                 colorEncTypeRef.current = '#ffffff';
// //                 categoryRef.current = ''; // resetting the ref value

                

// //             } catch (error) {
// //                 console.error("Error deleting document: ", error);
// //             }
// //         } else {
// //             alert('No encounter type selected for deletion.');
// //         }
// //     };


// //     return (
// //         <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
// //             <Box sx={{ position: 'relative', width: 500, padding: 4, background: 'white', borderRadius: 2 }}>
// //                 <h2>Manage Encounter Types</h2>
// //                 <form>
// //                     <FormControl fullWidth margin="normal" variant="outlined">
// //                         <InputLabel id="existing-types-label">Existing Types</InputLabel>
// //                         <Box display="flex" alignItems="center">
// //                             <Select
// //                                 onChange={(e) => {
// //                                     const selectedType = encounterTypes.find(type => type.name === e.target.value);
// //                                     setSelectedEncounterType(selectedType);
// //                                     nameEncRef.current = selectedType.name;
// //                                     colorEncTypeRef.current = selectedType.color;
// //                                     categoryRef.current = selectedType.category;
// //                                 }}
// //                                 labelId="existing-types-label"
// //                                 label="Existing Types"
// //                                 style={{ flex: 1 }}
// //                             >
// //                                 {encounterTypes.map((type, index) => (
// //                                     <MenuItem key={index} value={type.name}>{type.name}</MenuItem>
// //                                 ))}
// //                             </Select>
// //                             <Tooltip title="Clear">
// //                                 <RemoveCircleOutlineIcon
// //                                     style={{ cursor: 'pointer', marginLeft: '10px' }}
// //                                     onClick={unloadSelectedEncTypeItem}
// //                                 />
// //                             </Tooltip>
// //                         </Box>
// //                     </FormControl>

// //                     <TextField
// //                         label="Type Name"
// //                         fullWidth required
// //                         margin="normal"
// //                         variant="outlined"
// //                         inputProps={{ style: { paddingLeft: '15px' } }}
// //                         InputLabelProps={{
// //                             shrink: true, style: { backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px' }
// //                         }}
// //                         defaultValue={nameEncRef.current}
// //                         onChange={(e) => nameEncRef.current = e.target.value}
// //                         disabled={!!nameEncRef.current}
// //                     />

// //                     <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
// //                         <Typography variant="body1" style={{ marginRight: '10px' }}>
// //                             Color:
// //                         </Typography>
// //                         <input
// //                             type="color"
// //                             defaultValue={colorEncTypeRef.current}
// //                             onChange={(e) => colorEncTypeRef.current = e.target.value}
// //                             style={{ width: 250, height: 40, marginRight: '10px' }}
// //                         />
// //                     </Box>

// //                     <FormControl fullWidth margin="normal" variant="outlined">
// //                         <InputLabel id="category-label">Category</InputLabel>
// //                         <Select
// //                             defaultValue={categoryRef.current}
// //                             onChange={(e) => categoryRef.current = e.target.value}
// //                             labelId="category-label"
// //                             label="Category"
// //                         >
// //                             <MenuItem value="Regular Appointment">Regular Appointment</MenuItem>
// //                             <MenuItem value="Procedure Appointment">Procedure Appointment</MenuItem>
// //                         </Select>
// //                     </FormControl>

// //                     <Box display="flex" justifyContent="space-between" marginTop={2}>
// //                         <Button variant="contained" color="primary" style={{ width: '120px', marginRight: '20px' }} onClick={handleSaveOrUpdateEncounterType}>
// //                             {nameEncRef.current ? 'Update' : 'Save'}
// //                         </Button>
// //                         <Button variant="contained" style={{ width: '120px', backgroundColor: 'red', marginRight: '20px' }} onClick={handleDeleteEncounterType}>
// //                             Delete
// //                         </Button>
// //                         <Button 
// //                             variant="contained" 
// //                             style={{ width: '120px', backgroundColor: 'black' }} 
// //                             onClick={handleClose}
// //                         >
// //                             Cancel
// //                         </Button>
// //                     </Box>
// //                 </form>
// //             </Box>
// //         </Modal>
// //     );
// // }

// // export default EncTypeSettingsModal;
