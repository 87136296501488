import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Box, Backdrop, TextField } from '@mui/material';

const OverrideModal = ({ isOpen, onRequestClose, onVerified, accountEmail, providerName }) => {
    const codeRef = useRef(null);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (!isOpen) { 
            let timer = setTimeout(() => {
                setCode('');
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (isOpen && codeRef.current) {
                codeRef.current.focus();
            }
        }, 500);
    
        return () => clearTimeout(timer);
    }, [isOpen]);

    const submitButtonStyle = {
        margin: '20px',
        padding: '10px 20px',
        color: '#fff',
        fontWeight: 'bold',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        backgroundColor: '#007bff',
    };

    const handleCodeSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        try {
            const response = await fetch('https://nproapiserver.herokuapp.com/verify-2fa', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ accountEmail, providerName, token: code })
            });
            if (response.ok) {
                onVerified();
                alert('Code verified successfully!');
                onRequestClose();
            } else {
                alert('Failed to verify code. Please try again!');
            }
        } catch (error) {
            console.error('Failed to verify 2FA code:', error);
            alert('Error when verifying the code.');
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <Modal 
            open={isOpen} 
            onClose={onRequestClose}
            disableEnforceFocus
            disableAutoFocus
            disableRestoreFocus
            style={{ zIndex: 9999 }} // Ensure this is higher than other components
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                overflow: 'visible', 
                borderRadius: '10px',
                textAlign: 'center',
            }}>
                <h2>Please enter the code:</h2>
                <form onSubmit={handleCodeSubmit}>
                    <TextField
                        autoFocus
                        inputRef={codeRef}
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                        }}
                        type="number"
                        variant="outlined"
                        fullWidth
                        sx={{
                            marginBottom: '10px',
                            maxWidth: '70%',
                        }}
                    />
                    <button type="submit" style={submitButtonStyle}>SUBMIT</button>
                </form>
            </Box>
        </Modal>,
        document.body
    );
};

export default OverrideModal;


// import React, { useState, useRef, useEffect } from 'react';
// import { Modal, Box, Backdrop } from '@mui/material';
// import TextField from '@mui/material/TextField';

// const OverrideModal = ({ isOpen, onRequestClose, onVerified, accountEmail, providerName }) => {
//     const codeRef = useRef(null);
//     const [code, setCode] = useState('');

//     useEffect(() => {
//         if (!isOpen) { 
//             let timer = setTimeout(() => {
//                 setCode('');
//             }, 10000); // Try a longer delay to ensure all modal animations are complete
//             return () => clearTimeout(timer);
//         }
//     }, [isOpen]);

//     useEffect(() => {
//         let timer = setTimeout(() => {
//             if (isOpen && codeRef.current) {
//                 codeRef.current.focus();
//             }
//         }, 500); // Try a longer delay to ensure all modal animations are complete
    
//         return () => clearTimeout(timer);
//     }, [isOpen]);

//     const submitButtonStyle = {
//         margin: '20px',
//         padding: '10px 20px',
//         color: '#fff',
//         fontWeight: 'bold',
//         border: 'none',
//         borderRadius: '5px',
//         cursor: 'pointer',
//         backgroundColor: '#007bff',
//     };

//     const handleCodeSubmit = async (event) => {
//         event.preventDefault();
//         event.stopPropagation();
        
//         try {
//             const response = await fetch('https://nproapiserver.herokuapp.com/verify-2fa', {
//                 method: 'POST',
//                 headers: {'Content-Type': 'application/json'},
//                 body: JSON.stringify({ accountEmail, providerName, token: code })
//             });
//             if (response.ok) {
//                 onVerified();
//                 alert('Code verified successfully!');
//                 onRequestClose();
//             } else {
//                 alert('Failed to verify code. Please try again!');
//             }
//         } catch (error) {
//             console.error('Failed to verify 2FA code:', error);
//             alert('Error when verifying the code.');
//         }
//     };

//     return (
//         <Modal 
//             open={isOpen} 
//             onClose={onRequestClose}
//             disableEnforceFocus  // Helps if focus management is an issue
//             disableAutoFocus    // Prevents the modal from automatically focusing the first focusable element
//             disableRestoreFocus // Prevents modal from restoring focus to previously focused element upon close
//         >
//             <Box sx={{
//                 position: 'absolute',
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 width: 400,
//                 bgcolor: 'background.paper',
//                 border: '2px solid #000',
//                 boxShadow: 24,
//                 p: 4,
//                 overflow: 'visible', 
//                 borderRadius: '10px',
//                 textAlign: 'center',
//                 zIndex: (theme) => theme.zIndex.modal + 1, // Make sure the modal is above the overlay    
//             }}>
//                 <h2>Please enter the code:</h2>
//                 <form onSubmit={handleCodeSubmit}>
//                     <TextField
//                         autoFocus
//                         inputRef={codeRef}
//                         value={code}
//                         onChange={(e) => {
//                             console.log(e.target.value); // Check if this logs values as you type
//                             setCode(e.target.value);
//                         }}
//                         type="number"
//                         variant="outlined"
//                         fullWidth
//                         sx={{
//                             marginBottom: '10px',
//                             maxWidth: '70%',
//                         }}
//                     />
//                     <button type="submit" style={submitButtonStyle}>SUBMIT</button>
//                 </form>
//             </Box>
//         </Modal>
//     );
// };

// export default OverrideModal;


// // import React, { useState, useRef, useEffect } from 'react';
// // import Modal from '@mui/material/Modal';
// // import TextField from '@mui/material/TextField';
// // import Box from '@mui/material/Box';

// // const OverrideModal = ({ isOpen, onRequestClose, onVerified, accountEmail, providerName }) => {
// //     const codeRef = useRef(null);
// //     const [code, setCode] = useState('');

// //     useEffect(() => {
// //         let timer = setTimeout(() => {
// //             if (isOpen && codeRef.current) {
// //                 codeRef.current.focus();
// //             }
// //         }, 500); // Try a longer delay to ensure all modal animations are complete
    
// //         return () => clearTimeout(timer);
// //     }, [isOpen]);

// //     const submitButtonStyle = {
// //         margin: '20px',
// //         padding: '10px 20px',
// //         color: '#fff',
// //         fontWeight: 'bold',
// //         border: 'none',
// //         borderRadius: '5px',
// //         cursor: 'pointer',
// //         backgroundColor: '#007bff',
// //     };

// //     const handleCodeSubmit = async (event) => {
// //         event.stopPropagation();
// //         // const accountEmail = "user@example.com";
// //         // const providerName = "providerName";
        
// //         try {
// //             const response = await fetch('https://nproapiserver.herokuapp.com/verify-2fa', {
// //                 method: 'POST',
// //                 headers: {'Content-Type': 'application/json'},
// //                 body: JSON.stringify({ accountEmail, providerName, token: code })
// //             });
// //             if (response.ok) {
// //                 onVerified();
// //                 alert('Code verified successfully!');
// //                 onRequestClose();
// //             } else {
// //                 alert('Failed to verify code. Please try again!');
// //             }
// //         } catch (error) {
// //             console.error('Failed to verify 2FA code:', error);
// //             alert('Error when verifying the code.');
// //         }
// //     };

// //     return (
// //         <Modal 
// //             open={isOpen} 
// //             onClose={onRequestClose}
// //             disableEnforceFocus  // Helps if focus management is an issue
// //             disableAutoFocus    // Prevents the modal from automatically focusing the first focusable element
// //             disableRestoreFocus // Prevents modal from restoring focus to previously focused element upon close
// //         >
// //             <Box sx={{
// //                 position: 'absolute',
// //                 top: '50%',
// //                 left: '50%',
// //                 transform: 'translate(-50%, -50%)',
// //                 width: 400,
// //                 bgcolor: 'background.paper',
// //                 border: '2px solid #000',
// //                 boxShadow: 24,
// //                 p: 4,
// //                 overflow: 'visible', 
// //                 borderRadius: '10px',
// //                 textAlign: 'center',
// //                 zIndex: 1600  // Ensure this modal is on top when open
// //             }}>
// //                 <h2>Please enter the code:</h2>
// //                 <TextField
// //                     autoFocus
// //                     inputRef={codeRef}
// //                     value={code}
// //                     onChange={(e) => {
// //                         console.log(e.target.value); // Check if this logs values as you type
// //                         setCode(e.target.value);
// //                     }}
// //                     type="number"
// //                     variant="outlined"
// //                     fullWidth
// //                     sx={{
// //                         marginBottom: '10px',
// //                         maxWidth: '70%',
// //                     }}
// //                 />
// //                 <button style={submitButtonStyle} onClick={handleCodeSubmit}>SUBMIT</button>
// //             </Box>
// //         </Modal>
// //     );
// // };

// // export default OverrideModal;
