import React, { useState, useContext } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// Assuming that you might use context or props to get these values
// import { UserContext } from '../contexts/UserContext';

const Enable2FAModal = ({ isOpen, onRequestClose,accountEmail, providerName }) => {
    const [qrCodeUrl, setQrCodeUrl] = useState('');

    // Suppose user details are obtained from context or are passed down as props
    // const { accountEmail, providerName } = useContext(UserContext);

    const enable2FA = async () => {
        // const accountEmail = "user@example.com"; // Replace with actual dynamic value
        // const providerName = "providerName"; // Replace with actual dynamic value

        try {
            const response = await fetch('https://nproapiserver.herokuapp.com/enable-2fa', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ accountEmail, providerName }) // Sending required data to the server
            });
            const data = await response.json();
            if (response.ok) {
                setQrCodeUrl(data.qrCodeUrl); // Setting the QR code URL received from server
            } else {
                alert('Failed to enable 2FA. Please try again!');
            }
        } catch (error) {
            console.error('Error enabling 2FA:', error);
            alert('Error when enabling 2FA.');
        }
    };

    return (
        <Modal open={isOpen} onClose={onRequestClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h2>Enable Two-Factor Authentication</h2>
                {qrCodeUrl ? (
                    <>
                        <p>Scan this QR code with your Google Authenticator app to enable 2FA.</p>
                        <img src={qrCodeUrl} alt="QR Code" />
                    </>
                ) : (
                    <button onClick={enable2FA}>Enable 2FA</button>
                )}
            </Box>
        </Modal>
    );
};

export default Enable2FAModal;